// src/components/ChatInterface.jsx
import { useState, useRef, useEffect } from 'react';
import { Send } from 'lucide-react';
import PropTypes from 'prop-types';
import { sendChatMessage } from '../utils/api';

const ChatInterface = ({ messages, onUpdateMessages }) => {
  // Remove the local messages state
  // const [messages, setMessages] = useState([...]);

  const [inputMessage, setInputMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!inputMessage.trim()) return;

    // Update messages through the prop
    onUpdateMessages([...messages, { role: 'user', content: inputMessage }]);
    setInputMessage('');
    setIsLoading(true);

    try {
      const data = await sendChatMessage(inputMessage);
      onUpdateMessages([...messages,
        { role: 'user', content: inputMessage },
        { role: 'assistant', content: data.response }
      ]);
    } catch (error) {
      console.error('Error:', error);
      onUpdateMessages([...messages,
      { role: 'user', content: inputMessage },
      { role: 'assistant', content: "I'm sorry, I encountered an error. Please try again." }
      ]);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex flex-col h-[calc(100vh-5rem)] w-full mx-auto p-4">
      <div className="flex-1 overflow-y-auto mb-4 space-y-4 bg-white rounded-lg p-6 shadow-soft">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${message.role === 'user' ? 'justify-end' : 'justify-start'}`}
          >
            <div
              className={`max-w-[80%] p-3 rounded-lg text-base ${message.role === 'user'
                  ? 'bg-huntd-info text-white'
                  : 'bg-huntd-light text-huntd-primary'
                }`}
            >
              {message.content.split('\n').map((line, i) => (
                <p key={i} className="mb-1 last:mb-0">
                  {line}
                </p>
              ))}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start">
            <div className="bg-huntd-light text-huntd-primary p-3 rounded-lg max-w-[80%] text-base flex items-center">
              <span className="mr-2">Thinking...</span>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>

      <form onSubmit={handleSubmit} className="flex gap-2">
        <input
          type="text"
          value={inputMessage}
          onChange={(e) => setInputMessage(e.target.value)}
          placeholder="Ask me anything about my professional background..."
          className="flex-1 p-3 border border-huntd-secondary/30 rounded-lg focus:outline-none focus:border-huntd-info text-base"
          disabled={isLoading}
        />
        <button
          type="submit"
          disabled={isLoading}
          className="p-3 bg-huntd-info text-white rounded-lg hover:bg-huntd-info/80 disabled:bg-huntd-info/50 disabled:cursor-not-allowed border border-huntd-info"
        >
          <Send className="w-5 h-5" />
        </button>
      </form>
    </div>
  );
};

ChatInterface.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    role: PropTypes.oneOf(['user', 'assistant']).isRequired,
    content: PropTypes.string.isRequired
  })).isRequired,
  onUpdateMessages: PropTypes.func.isRequired
};

export default ChatInterface;
