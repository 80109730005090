import { useState, useEffect } from 'react';
import fetchWithAuth from '../utils/fetch-with-auth';
import { API_BASE_URL } from '../config';

const ProfileManager = () => {
  const [profile, setProfile] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [message, setMessage] = useState('');

  // Load profile data
  useEffect(() => {
    const loadProfile = async () => {
      try {
        const data = await fetchWithAuth(`${API_BASE_URL}/api/profile`);
        setProfile(data);
        setLoading(false);
      } catch (error) {
        console.error('Failed to load profile:', error);
        setError('Failed to load profile');
        setLoading(false);
      }
    };

    loadProfile();
  }, []);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setMessage('');
    
    try {
      const data = await fetchWithAuth(`${API_BASE_URL}/api/profile`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          firstName: profile.profile.firstName,
          lastName: profile.profile.lastName,
          phoneNumber: profile.profile.phoneNumber,
          location: profile.profile.location,
          title: profile.profile.title,
          bio: profile.profile.bio,
          emailNotifications: profile.preferences.emailNotifications,
          profileVisibility: profile.preferences.profileVisibility
        }),
      });
      setProfile(data);
      setMessage('Profile updated successfully');
    } catch (error) {
      console.error('Failed to update profile:', error);
      setError('Failed to update profile');
    }
  };

  // Handle input changes
  const handleChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      profile: {
        ...prev.profile,
        [field]: value
      }
    }));
  };

  // Handle preference changes
  const handlePreferenceChange = (field, value) => {
    setProfile(prev => ({
      ...prev,
      preferences: {
        ...prev.preferences,
        [field]: value
      }
    }));
  };

  if (loading) return <div>Loading...</div>;
  if (error) return <div className="text-red-600">{error}</div>;
  if (!profile) return <div>No profile data available</div>;

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-2xl font-bold mb-4">Profile Management</h2>
      
      {message && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {message}
        </div>
      )}

      {!profile.profileComplete && (
        <div className="bg-yellow-100 border border-yellow-400 text-yellow-700 px-4 py-3 rounded mb-4">
          Please complete your profile to access all features
        </div>
      )}

      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">First Name *</label>
            <input
              type="text"
              value={profile.profile.firstName || ''}
              onChange={(e) => handleChange('firstName', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>
          
          <div>
            <label className="block text-sm font-medium text-gray-700">Last Name *</label>
            <input
              type="text"
              value={profile.profile.lastName || ''}
              onChange={(e) => handleChange('lastName', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
              required
            />
          </div>
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Professional Title *</label>
          <input
            type="text"
            value={profile.profile.title || ''}
            onChange={(e) => handleChange('title', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Phone Number</label>
          <input
            type="tel"
            value={profile.profile.phoneNumber || ''}
            onChange={(e) => handleChange('phoneNumber', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Location</label>
          <input
            type="text"
            value={profile.profile.location || ''}
            onChange={(e) => handleChange('location', e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-gray-700">Bio</label>
          <textarea
            value={profile.profile.bio || ''}
            onChange={(e) => handleChange('bio', e.target.value)}
            rows="4"
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
          />
        </div>

        <div className="space-y-2">
          <h3 className="text-lg font-medium">Preferences</h3>
          
          <div className="flex items-center">
            <input
              type="checkbox"
              id="emailNotifications"
              checked={profile.preferences.emailNotifications}
              onChange={(e) => handlePreferenceChange('emailNotifications', e.target.checked)}
              className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
            />
            <label htmlFor="emailNotifications" className="ml-2 block text-sm text-gray-700">
              Receive email notifications
            </label>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700">Profile Visibility</label>
            <select
              value={profile.preferences.profileVisibility}
              onChange={(e) => handlePreferenceChange('profileVisibility', e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500"
            >
              <option value="private">Private</option>
              <option value="public">Public</option>
            </select>
          </div>
        </div>

        <div className="flex justify-end">
          <button
            type="submit"
            className="bg-blue-600 text-white px-4 py-2 rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
          >
            Save Changes
          </button>
        </div>
      </form>

      {profile.stats && (
        <div className="mt-8">
          <h3 className="text-lg font-medium mb-2">Your Activity</h3>
          <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-2xl font-bold">{profile.stats.jobApplications}</div>
              <div className="text-sm text-gray-500">Job Applications</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-2xl font-bold">{profile.stats.projects}</div>
              <div className="text-sm text-gray-500">Projects</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-2xl font-bold">{profile.stats.roles}</div>
              <div className="text-sm text-gray-500">Roles</div>
            </div>
            <div className="bg-gray-50 p-4 rounded-lg">
              <div className="text-2xl font-bold">{profile.stats.skills}</div>
              <div className="text-sm text-gray-500">Skills</div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileManager;
