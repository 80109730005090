// api-functions.js
import { API_BASE_URL } from '../config';
import fetchWithAuth from './fetch-with-auth';

export async function downloadCV(data) {
  return fetchWithAuth(`${API_BASE_URL}/api/cv/generate`, {
    method: 'POST',
    body: JSON.stringify({ data }),
  });
}

export async function uploadCV(file) {
  const formData = new FormData();
  formData.append('cv', file);

  return fetchWithAuth(`${API_BASE_URL}/api/cv/upload`, {
    method: 'POST',
    headers: {}, // Let browser set content-type for FormData
    body: formData,
  });
}

export async function uploadCVAndExtract(file, onProgress) {
  const formData = new FormData();
  formData.append('cv', file);

  return fetchWithAuth(`${API_BASE_URL}/api/cv/upload`, {
    method: 'POST',
    body: formData,
    onProgress,
  });
}

export async function createRoleFromCV(role) {
  return fetchWithAuth(`${API_BASE_URL}/api/roles`, {
    method: 'POST',
    body: JSON.stringify(role),
  });
}

export async function getSkills() {
  return fetchWithAuth(`${API_BASE_URL}/api/skills`);
}

export async function getRoles() {
  return fetchWithAuth(`${API_BASE_URL}/api/roles`);
}

export async function getProjects() {
  return fetchWithAuth(`${API_BASE_URL}/api/projects`);
}

export async function createRole(role) {
  return fetchWithAuth(`${API_BASE_URL}/api/roles`, {
    method: 'POST',
    body: JSON.stringify(role),
  });
}

export async function updateRole(id, role) {
  return fetchWithAuth(`${API_BASE_URL}/api/roles/${id}`, {
    method: 'PUT',
    body: JSON.stringify(role),
  });
}

export async function deleteRole(id) {
  await fetchWithAuth(`${API_BASE_URL}/api/roles/${id}`, {
    method: 'DELETE',
  });
  return true;
}

export async function createProject(project) {
  return fetchWithAuth(`${API_BASE_URL}/api/projects`, {
    method: 'POST',
    body: JSON.stringify(project),
  });
}

export async function updateProject(id, project) {
  return fetchWithAuth(`${API_BASE_URL}/api/projects/${id}`, {
    method: 'PUT',
    body: JSON.stringify(project),
  });
}

export async function deleteProject(id) {
  await fetchWithAuth(`${API_BASE_URL}/api/projects/${id}`, {
    method: 'DELETE',
  });
  return true;
}

export async function createSkill(skill) {
  return fetchWithAuth(`${API_BASE_URL}/api/skills`, {
    method: 'POST',
    body: JSON.stringify(skill),
  });
}

export async function updateSkill(id, skill) {
  return fetchWithAuth(`${API_BASE_URL}/api/skills/${id}`, {
    method: 'PUT',
    body: JSON.stringify(skill),
  });
}

export async function deleteSkill(id) {
  await fetchWithAuth(`${API_BASE_URL}/api/skills/${id}`, {
    method: 'DELETE',
  });
  return true;
}

export async function createJobApplication(application) {
  return fetchWithAuth(`${API_BASE_URL}/api/job-applications`, {
    method: 'POST',
    body: JSON.stringify(application),
  });
}

export async function getJobApplications() {
  return fetchWithAuth(`${API_BASE_URL}/api/job-applications`);
}

export async function updateJobApplication(id, data) {
  return fetchWithAuth(`${API_BASE_URL}/api/job-applications/${id}`, {
    method: 'PUT',
    body: JSON.stringify(data),
  });
}

export async function getJobApplicationStats() {
  return fetchWithAuth(`${API_BASE_URL}/api/job-applications/stats`);
}

export async function deleteJobApplication(id) {
  await fetchWithAuth(`${API_BASE_URL}/api/job-applications/${id}`, {
    method: 'DELETE',
  });
  return true;
}

export async function uploadJobSpec(id, file) {
  const formData = new FormData();
  formData.append('spec', file);

  return fetchWithAuth(`${API_BASE_URL}/api/job-applications/${id}/spec`, {
    method: 'POST',
    headers: {}, // Let browser set content-type for FormData
    body: formData,
  });
}

export async function sendChatMessage(message) {
  return fetchWithAuth(`${API_BASE_URL}/api/chat`, {
    method: 'POST',
    body: JSON.stringify({ message }),
  });
}
