import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';

const LandingPage = () => {
  const { user, login, logout } = useAuth();
  const navigate = useNavigate();

  const handleDashboard = () => {
    navigate('/');
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full space-y-8 p-8">
        <div className="text-center">
          <img 
            src="/huntd_logo.png" 
            alt="Huntd Logo" 
            className="mx-auto w-96 mb-8"
          />
          {user ? (
            <div className="space-y-4">
              <div className="text-lg text-gray-700 mb-4">
                Welcome back, {user.userDetails}!
              </div>
              <button
                onClick={handleDashboard}
                className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#2E7D32] hover:bg-[#1B5E20] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2E7D32] mb-2"
              >
                Go to Dashboard
              </button>
              <button
                onClick={logout}
                className="w-full flex items-center justify-center px-4 py-3 border border-gray-300 text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2E7D32]"
              >
                Sign Out
              </button>
            </div>
          ) : (
            <button
              onClick={login}
              className="w-full flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md text-white bg-[#2E7D32] hover:bg-[#1B5E20] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#2E7D32]"
            >
              Sign in to get started
            </button>
          )}
        </div>
        <div className="mt-8 space-y-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-medium text-gray-900">Track Applications</h3>
              <p className="mt-2 text-gray-600">
                Keep track of all your job applications in one place
              </p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-medium text-gray-900">AI Assistant</h3>
              <p className="mt-2 text-gray-600">
                Get help with your applications from our AI assistant
              </p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-medium text-gray-900">CV Management</h3>
              <p className="mt-2 text-gray-600">
                Generate tailored CVs for each application
              </p>
            </div>
            <div className="p-4 bg-white rounded-lg shadow">
              <h3 className="text-lg font-medium text-gray-900">Progress Tracking</h3>
              <p className="mt-2 text-gray-600">
                Monitor the status of each application
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
