import { useState } from 'react';
import PropTypes from 'prop-types';
import { downloadCV } from '../utils/api';

const CVDownload = ({ cvData, className }) => {
  const [loading, setLoading] = useState(false);
  const [urls, setUrls] = useState(null);

  const handleDownload = async () => {
    try {
      setLoading(true);
      const result = await downloadCV(cvData);
      setUrls(result);
    } catch (err) {
      console.error('CV generation error:', err);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <button disabled className={className}>
        Generating...
      </button>
    );
  }

  if (urls) {
    return (
      <>
        <a
          href={urls.pdf}
          download
          className={className}
        >
          Download PDF
        </a>
        <a
          href={urls.docx}
          download
          className={className}
        >
          Download Word Document
        </a>
      </>
    );
  }

  // Don't allow generation if there's no data
  if (!cvData.roles.length) {
    return (
      <button
        disabled
        className={className}
        title="Add some roles in the Admin Panel first"
      >
        No CV Data Available
      </button>
    );
  }

  return (
    <button
      onClick={handleDownload}
      disabled={loading}
      className={className}
    >
      Generate CV
    </button>
  );
};

CVDownload.propTypes = {
  cvData: PropTypes.shape({
    name: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string),
    roles: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      company: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      description: PropTypes.string
    })).isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    }))
  }).isRequired,
  className: PropTypes.string
};

export default CVDownload;
