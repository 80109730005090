import PropTypes from 'prop-types';
import CVDownload from './CVDownload';
import RoleSection from './RoleSection';
import ProjectSection from './ProjectSection';
import SkillsSection from './SkillsSection';
import { getRoles, getProjects, getSkills } from '../utils/api';

const AdminPanel = ({ data, setData, loading, error }) => {
  // Validate data structure
  if (!data || !data.roles || !data.projects || !data.skills) {
    console.error('Invalid data structure:', data);
    return null;
  }
  const fetchData = async () => {
    try {
      const [roles, projects, skills] = await Promise.all([
        getRoles(),
        getProjects(),
        getSkills()
      ]);

      setData({
        roles: roles.map(role => ({
          ...role,
          startDate: role.startDate.substring(0, 7),
          endDate: role.endDate?.substring(0, 7)
        })),
        projects,
        skills: skills.map(s => s.name)
      });
    } catch (err) {
      console.error('Error fetching CV data:', err);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-72">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-huntd-info"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="p-4 bg-huntd-error/10 text-huntd-error rounded">
        {error}
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <div className="bg-white rounded-lg shadow-soft p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-2xl font-bold">CV Management</h2>
          <div className="flex gap-4">
            <CVDownload cvData={data} />
          </div>
        </div>
        
        <div className="space-y-8">
          {/* Only show SkillsSection if there are roles */}
          {data.roles.length > 0 && (
            <SkillsSection 
              skills={data.skills} 
              onUpdate={() => fetchData()}
            />
          )}
          
          <RoleSection 
            roles={data.roles}
            onUpdate={() => fetchData()}
          />
          
          {/* Only show ProjectSection if there are roles */}
          {data.roles.length > 0 && (
            <ProjectSection 
              projects={data.projects}
              onUpdate={() => fetchData()}
            />
          )}
        </div>
      </div>
    </div>
  );
};

AdminPanel.propTypes = {
  data: PropTypes.shape({
    roles: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.string,
      company: PropTypes.string,
      startDate: PropTypes.string,
      endDate: PropTypes.string,
      description: PropTypes.string
    })).isRequired,
    projects: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    })).isRequired,
    skills: PropTypes.arrayOf(PropTypes.string).isRequired
  }).isRequired,
  setData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.string
};

export default AdminPanel;
