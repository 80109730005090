import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import config from '../config';

class MonitoringService {
  constructor() {
    this.appInsights = null;
    this.initialized = false;
    this.userId = null;
    this.operationId = null;
  }

  /**
   * Initialize Application Insights
   * @param {string} connectionString - The Application Insights connection string
   * @param {Object} user - The current user object (optional)
   */
  init(connectionString, user = null) {
    if (!connectionString) {
      console.log('Application Insights connection string not provided, telemetry disabled');
      return;
    }

    try {
      this.appInsights = new ApplicationInsights({
        config: {
          connectionString,
          enableCorsCorrelation: true,
          enableRequestHeaderTracking: true,
          enableResponseHeaderTracking: true,
          disableFetchTracking: false,
          enableAjaxErrorStatusText: true,
          enableAjaxPerfTracking: true,
          distributedTracingMode: 2, // DistributedTracingModes.AI_AND_W3C
          disableExceptionTracking: false,
          autoTrackPageVisitTime: true,
          enableUnhandledPromiseRejectionTracking: true,
          maxBatchInterval: 5000,
          maxBatchSizeInBytes: 10000,
          disableFlushOnBeforeUnload: false,
          // Set cloud role name for better identification in the portal
          cloudRoleName: 'cv-chatbot-frontend',
        }
      });

      // Start the SDK
      this.appInsights.loadAppInsights();
      this.initialized = true;

      // Set authenticated user context if available
      if (user) {
        this.setAuthenticatedUserContext(user);
      }

      // Track initialization success
      this.trackEvent('AppInsightsInitialized', { 
        environment: config.isDevelopment ? 'development' : 'production'
      });

      console.log('Application Insights initialized successfully');
    } catch (error) {
      console.error('Failed to initialize Application Insights:', error);
    }
  }

  /**
   * Set authenticated user context
   * @param {Object} user - The user object
   */
  setAuthenticatedUserContext(user) {
    if (!this.initialized || !user) return;

    try {
      const userId = user.userId || user.id;
      const userDetails = user.userDetails || user.email;

      if (userId) {
        this.userId = userId;
        this.appInsights.setAuthenticatedUserContext(userId, userDetails, true);
        
        // Track user login event
        this.trackEvent('UserAuthenticated', {
          identityProvider: user.identityProvider || 'unknown'
        });
      }
    } catch (error) {
      console.error('Error setting authenticated user context:', error);
    }
  }

  /**
   * Clear authenticated user context (on logout)
   */
  clearAuthenticatedUserContext() {
    if (!this.initialized) return;
    
    try {
      this.appInsights.clearAuthenticatedUserContext();
      this.userId = null;
      
      // Track user logout event
      this.trackEvent('UserLoggedOut');
    } catch (error) {
      console.error('Error clearing authenticated user context:', error);
    }
  }

  /**
   * Track a page view
   * @param {string} name - The name of the page
   * @param {Object} properties - Additional properties to track
   */
  trackPageView(name, properties = {}) {
    if (!this.initialized) return;
    
    try {
      this.appInsights.trackPageView({
        name,
        properties: {
          ...properties,
          environment: config.isDevelopment ? 'development' : 'production'
        }
      });
    } catch (error) {
      console.error('Error tracking page view:', error);
    }
  }

  /**
   * Track a custom event
   * @param {string} name - The name of the event
   * @param {Object} properties - Additional properties to track
   */
  trackEvent(name, properties = {}) {
    if (!this.initialized) return;
    
    try {
      this.appInsights.trackEvent({ 
        name, 
        properties: {
          ...properties,
          userId: this.userId,
          environment: config.isDevelopment ? 'development' : 'production'
        }
      });
    } catch (error) {
      console.error('Error tracking event:', error);
    }
  }

  /**
   * Track an exception
   * @param {Error} exception - The exception to track
   * @param {Object} properties - Additional properties to track
   */
  trackException(exception, properties = {}) {
    if (!this.initialized) return;
    
    try {
      this.appInsights.trackException({
        exception,
        properties: {
          ...properties,
          userId: this.userId,
          environment: config.isDevelopment ? 'development' : 'production'
        }
      });
    } catch (error) {
      console.error('Error tracking exception:', error);
    }
  }

  /**
   * Track a metric
   * @param {string} name - The name of the metric
   * @param {number} value - The value of the metric
   * @param {Object} properties - Additional properties to track
   */
  trackMetric(name, value, properties = {}) {
    if (!this.initialized) return;
    
    try {
      this.appInsights.trackMetric({
        name,
        average: value,
        properties: {
          ...properties,
          userId: this.userId,
          environment: config.isDevelopment ? 'development' : 'production'
        }
      });
    } catch (error) {
      console.error('Error tracking metric:', error);
    }
  }

  /**
   * Set the operation ID for correlation with backend
   * @param {string} id - The operation ID
   */
  setOperationId(id) {
    if (!this.initialized) return;
    
    try {
      this.operationId = id;
      this.appInsights.context.telemetryTrace.traceID = id;
    } catch (error) {
      console.error('Error setting operation ID:', error);
    }
  }

  /**
   * Get the current operation ID
   * @returns {string} The operation ID
   */
  getOperationId() {
    return this.operationId;
  }

  /**
   * Flush any pending telemetry
   */
  flush() {
    if (!this.initialized) return;
    
    try {
      this.appInsights.flush();
    } catch (error) {
      console.error('Error flushing telemetry:', error);
    }
  }
}

// Create and export a singleton instance
const monitoringService = new MonitoringService();
export default monitoringService;
