import { createContext, useContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { API_BASE_URL } from '../config';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        const response = await fetch('/.auth/me');
        const data = await response.json();
        
        if (data.clientPrincipal) {
          setUser(data.clientPrincipal);
          
          // Convert SWA token to our custom JWT
          const convertResponse = await fetch(`${API_BASE_URL}/api/auth/convert`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-MS-CLIENT-PRINCIPAL-ID': data.clientPrincipal.userId,
              'X-MS-CLIENT-PRINCIPAL-NAME': data.clientPrincipal.userDetails
            }
          });
          
          if (!convertResponse.ok) {
            throw new Error('Failed to convert token');
          }
          
          const { token } = await convertResponse.json();
          setToken(token);
          localStorage.setItem('jwt', token);
        } else {
          setUser(null);
          setToken(null);
          localStorage.removeItem('jwt');
        }
      } catch (error) {
        console.error('Auth check failed:', error);
        setUser(null);
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = () => {
    window.location.href = '/.auth/login/aad';
  };

  const logout = () => {
    localStorage.removeItem('jwt');
    window.location.href = '/.auth/logout';
  };

  // Refresh token if it's expired
  const refreshToken = async () => {
    try {
      const response = await fetch('/.auth/me');
      const data = await response.json();
      
      if (data.clientPrincipal) {
        const convertResponse = await fetch(`${API_BASE_URL}/api/auth/convert`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-MS-CLIENT-PRINCIPAL-ID': data.clientPrincipal.userId,
            'X-MS-CLIENT-PRINCIPAL-NAME': data.clientPrincipal.userDetails
          }
        });
        
        if (!convertResponse.ok) {
          throw new Error('Failed to refresh token');
        }
        
        const { token: newToken } = await convertResponse.json();
        setToken(newToken);
        localStorage.setItem('jwt', newToken);
        return newToken;
      }
      
      throw new Error('No client principal found');
    } catch (error) {
      console.error('Token refresh failed:', error);
      logout();
      throw error;
    }
  };

  return (
    <AuthContext.Provider value={{ user, token, loading, login, logout, refreshToken }}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
