// src/components/ProjectSection.jsx
import { useState, useEffect } from 'react';
import { 
  PlusCircle, 
  Trash2, 
  Edit, 
  Lock, 
  Globe, 
  RefreshCw 
} from 'lucide-react';
import { 
  PrimaryButton, 
  SecondaryButton, 
  DangerButton,
  FormInput,
  FormTextArea,
  FormSelect,
  Card,
  CardHeader,
  CardContent 
} from './shared/FormStyles';
import { getRoles, getProjects, createProject, updateProject, deleteProject } from '../utils/api';

const ProjectSection = () => {
  // State for data
  const [roles, setRoles] = useState([]);
  const [projects, setProjects] = useState([]);
  
  // State for UI
  const [isAdding, setIsAdding] = useState(false);
  const [editingId, setEditingId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  // Form state
  const [formData, setFormData] = useState({
    roleId: '',
    clientName: '',
    isClientPrivate: false,
    sector: '',
    size: '',
    description: '',
    achievements: '',
    startDate: '',
    endDate: ''
  });

  // Data fetching handlers
  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles');
    }
  };

  const fetchProjects = async () => {
    try {
      const data = await getProjects();
      setProjects(data);
    } catch (error) {
      console.error('Error fetching projects:', error);
      setError('Failed to fetch projects');
    }
  };

  // Form submission handler
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    // Validate dates
    const selectedRole = roles.find(role => role._id === formData.roleId);
    if (!selectedRole) {
      setError('Please select a role');
      setIsLoading(false);
      return;
    }

    const projectStart = new Date(formData.startDate);
    const roleStart = new Date(selectedRole.startDate);
    const roleEnd = selectedRole.endDate ? new Date(selectedRole.endDate) : new Date();

    // Project start date must be within role dates
    if (projectStart < roleStart || projectStart > roleEnd) {
      setError('Project start date must be within the role dates');
      setIsLoading(false);
      return;
    }

    // If project has end date, validate it
    if (formData.endDate) {
      const projectEnd = new Date(formData.endDate);
      if (projectEnd < projectStart) {
        setError('Project end date cannot be before start date');
        setIsLoading(false);
        return;
      }
      if (projectEnd > roleEnd) {
        setError('Project end date cannot be after role end date');
        setIsLoading(false);
        return;
      }
    }
    
    try {
      // Format the data
      const formattedData = {
        ...formData,
        achievements: formData.achievements.split(',').map(a => a.trim()).filter(Boolean)
      };

      if (editingId) {
        await updateProject(editingId, formattedData);
      } else {
        await createProject(formattedData);
      }

      // Refresh the projects list
      await fetchProjects();
      
      // Reset form and state
      resetForm();
    } catch (error) {
      console.error('Error saving project:', error);
      setError('Failed to save project');
    } finally {
      setIsLoading(false);
    }
  };

  // Delete handler
  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this project?')) return;

    setIsLoading(true);
    setError(null);

    try {
      await deleteProject(id);
      await fetchProjects();
    } catch (error) {
      console.error('Error deleting project:', error);
      setError('Failed to delete project');
    } finally {
      setIsLoading(false);
    }
  };

  // Edit handler
  const handleEdit = (project) => {
    setEditingId(project._id);
    setFormData({
      roleId: project.roleId,
      clientName: project.clientName,
      isClientPrivate: project.isClientPrivate,
      sector: project.sector,
      size: project.size || '',
      description: project.description || '',
      achievements: Array.isArray(project.achievements) 
        ? project.achievements.join(', ') 
        : '',
      startDate: project.startDate.substring(0, 7), // Format YYYY-MM
      endDate: project.endDate ? project.endDate.substring(0, 7) : ''
    });
    setIsAdding(true);
  };

  // Form reset handler
  const resetForm = () => {
    setFormData({
      roleId: '',
      clientName: '',
      isClientPrivate: false,
      sector: '',
      size: '',
      description: '',
      achievements: '',
      startDate: '',
      endDate: ''
    });
    setEditingId(null);
    setIsAdding(false);
  };

  // Form field change handler
  const handleFormChange = (field, value) => {
    setFormData(prev => ({
      ...prev,
      [field]: value
    }));
  };

  // useEffect for initial data loading
  useEffect(() => {
    fetchRoles();
    fetchProjects();
  }, []);

  return (
    <div className="space-y-6">
      {/* Error Display */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      {/* Add Button */}
      {!isAdding && (
        <div className="flex justify-between items-center">
          <PrimaryButton
            onClick={() => setIsAdding(true)}
            className="flex items-center"
          >
            <PlusCircle size={20} className="mr-2" />
            Add New Project
          </PrimaryButton>

          <SecondaryButton
            onClick={fetchRoles}
            className="flex items-center"
          >
            <RefreshCw size={16} className="mr-2" />
            Refresh Roles List
          </SecondaryButton>
        </div>
      )}

      {/* Add/Edit Form */}
      {isAdding && (
        <Card className="mb-6">
          <CardHeader 
            title={editingId ? "Edit Project" : "Add New Project"}
            subtitle="Enter the project details"
          />
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              {/* Role Selection */}
              <div>
                <FormSelect
                  label="Associated Role"
                  required
                  value={formData.roleId}
                  onChange={(e) => handleFormChange('roleId', e.target.value)}
                >
                  <option value="">Select a role...</option>
                  {roles.map(role => (
                    <option key={role._id} value={role._id}>
                      {role.role} at {role.company}
                    </option>
                  ))}
                </FormSelect>
              </div>

              {/* Client Information Section */}
              <div className="bg-gray-50 p-4 rounded-lg space-y-4">
                <h3 className="font-medium text-gray-900">Client Information</h3>
                
                <FormInput
                  label="Client Name"
                  required
                  value={formData.clientName}
                  onChange={(e) => handleFormChange('clientName', e.target.value)}
                  placeholder="Name of the end client"
                />

                <div className="flex items-center space-x-2">
                  <input
                    type="checkbox"
                    id="isClientPrivate"
                    checked={formData.isClientPrivate}
                    onChange={(e) => handleFormChange('isClientPrivate', e.target.checked)}
                    className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                  />
                  <label htmlFor="isClientPrivate" className="text-sm text-gray-700 flex items-center">
                    <Lock className="w-4 h-4 mr-1" />
                    Keep client name private
                  </label>
                </div>
              </div>

              {/* Project Details */}
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  label="Sector"
                  required
                  value={formData.sector}
                  onChange={(e) => handleFormChange('sector', e.target.value)}
                  placeholder="e.g., Financial Services, Healthcare"
                />
                <FormInput
                  label="Size"
                  value={formData.size}
                  onChange={(e) => handleFormChange('size', e.target.value)}
                  placeholder="e.g., Fortune 500, FTSE 100"
                />
              </div>

              <FormTextArea
                label="Project Description"
                value={formData.description}
                onChange={(e) => handleFormChange('description', e.target.value)}
                rows={4}
                placeholder="Describe the project without revealing client identity if private"
              />

              <FormTextArea
                label="Achievements (comma-separated)"
                value={formData.achievements}
                onChange={(e) => handleFormChange('achievements', e.target.value)}
                rows={3}
                placeholder="Enter achievements, separated by commas"
              />

              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  label="Start Date"
                  required
                  type="month"
                  value={formData.startDate}
                  onChange={(e) => handleFormChange('startDate', e.target.value)}
                />
                <FormInput
                  label="End Date"
                  type="month"
                  value={formData.endDate}
                  onChange={(e) => handleFormChange('endDate', e.target.value)}
                />
              </div>

              <div className="flex justify-end gap-3">
                <SecondaryButton type="button" onClick={resetForm}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton type="submit" disabled={isLoading}>
                  {isLoading ? (
                    <RefreshCw className="animate-spin h-5 w-5" />
                  ) : (
                    editingId ? 'Update' : 'Save'
                  )} Project
                </PrimaryButton>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      {/* Projects List */}
      <div className="space-y-6">
        {roles.map(role => {
          const roleProjects = projects.filter(project => project.roleId === role._id);
          if (roleProjects.length === 0) return null;

          return (
            <div key={role._id} className="space-y-4">
              <h3 className="font-medium text-gray-900">
                {role.role} at {role.company}
              </h3>
              
              {roleProjects.map(project => (
                <Card key={project._id}>
                  <CardContent>
                    <div className="flex justify-between items-start">
                      <div>
                        <div className="flex items-center gap-2">
                          <h4 className="text-lg font-medium text-gray-900">
                            {project.clientName}
                          </h4>
                          {project.isClientPrivate ? (
                            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800">
                              <Lock className="w-3 h-3 mr-1" />
                              Private Client
                            </span>
                          ) : (
                            <span className="inline-flex items-center px-2 py-1 rounded-full text-xs font-medium bg-green-100 text-green-800">
                              <Globe className="w-3 h-3 mr-1" />
                              Public Client
                            </span>
                          )}
                        </div>
                        <p className="text-sm text-gray-600">
                          {project.sector} {project.size && `• ${project.size}`}
                        </p>
                        <p className="text-sm text-gray-500">
                          {new Date(project.startDate).toLocaleDateString('en-US', { 
                            month: 'short', 
                            year: 'numeric' 
                          })} - 
                          {project.endDate ? 
                            new Date(project.endDate).toLocaleDateString('en-US', { 
                              month: 'short', 
                              year: 'numeric' 
                            }) : 
                            ' Present'}
                        </p>
                      </div>

                      <div className="flex gap-2">
                        <SecondaryButton onClick={() => handleEdit(project)}>
                          <Edit size={16} className="mr-1" />
                          Edit
                        </SecondaryButton>
                        <DangerButton 
                          onClick={() => handleDelete(project._id)}
                          disabled={isLoading}
                        >
                          <Trash2 size={16} className="mr-1" />
                          Delete
                        </DangerButton>
                      </div>
                    </div>

                    {project.description && (
                      <p className="mt-3 text-gray-600">{project.description}</p>
                    )}

                    {project.achievements?.length > 0 && (
                      <div className="mt-3">
                        <h5 className="text-sm font-medium text-gray-900">Achievements</h5>
                        <ul className="mt-1 list-disc list-inside text-sm text-gray-600">
                          {project.achievements.map((achievement, index) => (
                            <li key={index}>{achievement}</li>
                          ))}
                        </ul>
                      </div>
                    )}
                  </CardContent>
                </Card>
              ))}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ProjectSection;
