import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { 
  LayoutDashboard, 
  MessageSquare, 
  Settings,
  LogIn,
  LogOut,
  User,
  Upload,
  PanelLeftClose,
  PanelLeft,
  UserCog
} from 'lucide-react';
import { useAuth } from '../contexts/AuthContext';
import PropTypes from 'prop-types';

const NavMenu = ({ onMenuVisibilityChange }) => {
  const location = useLocation();
  const currentPath = location.pathname;
  const { user, login, logout } = useAuth();
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  const [isMobile, setIsMobile] = useState(false);
  
  // Notify parent component when menu visibility changes
  useEffect(() => {
    if (onMenuVisibilityChange) {
      onMenuVisibilityChange(isMenuVisible);
    }
  }, [isMenuVisible, onMenuVisibilityChange]);
  
  // Initialize mobile state and handle resize events
  useEffect(() => {
    const checkMobile = () => {
      const mobile = window.innerWidth < 768;
      setIsMobile(mobile);
      
      // On mobile, hide menu by default on initial load only
      if (mobile && isMenuVisible && !document.documentElement.hasAttribute('data-menu-initialized')) {
        setIsMenuVisible(false);
      }
      
      // Mark as initialized
      document.documentElement.setAttribute('data-menu-initialized', 'true');
    };
    
    // Initial check
    checkMobile();
    
    // Set up event listener
    window.addEventListener('resize', checkMobile);
    
    // Cleanup
    return () => window.removeEventListener('resize', checkMobile);
  }, [isMenuVisible]);
  
  // Close mobile menu when route changes on mobile
  useEffect(() => {
    if (isMobile) {
      setIsMenuVisible(false);
    }
  }, [location, isMobile]);

  return (
    <>
      {/* Menu toggle button - positioned mostly on the nav menu */}
      <button 
        onClick={() => setIsMenuVisible(!isMenuVisible)}
        className={`fixed top-1/2 transform -translate-y-1/2 z-50 p-2 rounded-r-md bg-white shadow-md hover:bg-huntd-light transition-colors
          ${isMenuVisible ? 'left-44' : 'left-0'}`}
        aria-label={isMenuVisible ? "Hide menu" : "Show menu"}
      >
        {isMenuVisible ? 
          <PanelLeftClose className="w-6 h-6 text-huntd-info" /> : 
          <PanelLeft className="w-6 h-6 text-huntd-accent" />
        }
      </button>
      
      {/* Overlay when menu is open on mobile */}
      {isMenuVisible && isMobile && (
        <div 
          className="md:hidden fixed inset-0 bg-black bg-opacity-50 z-40"
          onClick={() => setIsMenuVisible(false)}
        />
      )}
      
      <nav className={`bg-white shadow-lg fixed top-0 left-0 z-40 h-screen transition-all duration-300 ease-in-out
        ${isMenuVisible ? 'translate-x-0' : '-translate-x-full'} 
        w-52`}
      >
        <div className="flex flex-col justify-between h-full p-4 w-52">
        {/* Top section with logo */}
        <div>
          <div className="flex justify-center mb-12">
            <img src="/huntd_logo.png" alt="Huntd Logo" className="w-full max-w-[200px]" />
          </div>
          
          {/* Navigation links */}
          <div className="space-y-2 pt-8">
            <div className="space-y-1">
              <Link
                to="/"
                className={`w-full px-4 py-2 text-base rounded flex items-center gap-2 ${
                  currentPath === '/' && !currentPath.startsWith('/applications')
                    ? 'bg-huntd-info text-white'
                    : 'border border-huntd-light hover:bg-huntd-light'
                }`}
              >
                <LayoutDashboard className="w-5 h-5" />
                Dashboard
              </Link>

              <Link
                to="/profile"
                className={`w-full px-4 py-2 text-base rounded flex items-center gap-2 ${
                  currentPath === '/profile'
                    ? 'bg-huntd-info text-white'
                    : 'border border-huntd-light hover:bg-huntd-light'
                }`}
              >
                <UserCog className="w-5 h-5" />
                Profile
              </Link>
            </div>
            
            <Link
              to="/admin"
              className={`w-full px-4 py-2 text-base rounded flex items-center gap-2 ${
                currentPath === '/admin'
                  ? 'bg-huntd-info text-white'
                  : 'border border-huntd-light hover:bg-huntd-light'
              }`}
            >
              <Settings className="w-5 h-5" />
              Experience
            </Link>

            <Link
              to="/cv-upload"
              className={`w-full px-4 py-2 text-base rounded flex items-center gap-2 ${
                currentPath === '/cv-upload'
                  ? 'bg-huntd-info text-white'
                  : 'border border-huntd-light hover:bg-huntd-light'
              }`}
            >
              <Upload className="w-5 h-5" />
              Upload CV
            </Link>

            <Link
              to="/chat"
              className={`w-full px-4 py-2 text-base rounded flex items-center gap-2 ${
                currentPath === '/chat'
                  ? 'bg-huntd-info text-white'
                  : 'border border-huntd-light hover:bg-huntd-light'
              }`}
            >
              <MessageSquare className="w-5 h-5" />
              Chat
            </Link>
          </div>
        </div>
        
        {/* Bottom section with user info/login */}
        <div className="mt-auto">
          {user ? (
            <div className="p-2 bg-huntd-light rounded-lg">
              <div className="flex items-center gap-2 text-sm">
                <User className="w-4 h-4" />
                <span className="truncate">{user.userDetails}</span>
              </div>
              <button
                onClick={logout}
                className="mt-2 w-full px-4 py-2 text-sm rounded flex items-center gap-2 bg-huntd-error text-white hover:bg-huntd-error/80 border border-huntd-error"
              >
                <LogOut className="w-4 h-4" />
                Sign Out
              </button>
            </div>
          ) : (
            <button
              onClick={login}
              className="w-full px-4 py-2 text-sm rounded flex items-center gap-2 bg-huntd-info text-white hover:bg-huntd-info/80 border border-huntd-info"
            >
              <LogIn className="w-4 h-4" />
              Sign In
            </button>
          )}
        </div>
        </div>
      </nav>
    </>
  );
};

NavMenu.propTypes = {
  onMenuVisibilityChange: PropTypes.func
};

NavMenu.defaultProps = {
  onMenuVisibilityChange: () => {}
};

export default NavMenu;
