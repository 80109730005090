import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { 
  Calendar, 
  Building2, 
  Briefcase, 
  Clock,
  CheckCircle,
  XCircle,
  UserCheck,
  FileText,
  AlertCircle,
  Trash2,
  MoreVertical
} from 'lucide-react';

const statusIcons = {
  'Draft': Clock,
  'Applied': CheckCircle,
  'Interviewing': UserCheck,
  'Offer': FileText,
  'Rejected': XCircle
};

const statusColors = {
  'Draft': 'bg-huntd-light text-huntd-primary',
  'Applied': 'bg-huntd-info/10 text-huntd-info',
  'Interviewing': 'bg-huntd-warning/10 text-huntd-warning',
  'Offer': 'bg-huntd-success/10 text-huntd-success',
  'Rejected': 'bg-huntd-error/10 text-huntd-error'
};

const JobApplicationCard = ({ application, onStatusChange, onDelete }) => {
  const StatusIcon = statusIcons[application.status] || AlertCircle;
  const statusColor = statusColors[application.status] || 'bg-huntd-light text-huntd-primary';

  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDelete = () => {
    if (window.confirm('Are you sure you want to delete this application?')) {
      onDelete(application._id);
    }
    setShowMenu(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <div className="bg-white rounded-lg shadow-soft p-6">
      <div className="flex justify-between items-start mb-4">
        <div>
          <h3 className="text-lg font-medium text-huntd-primary">{application.position}</h3>
          <div className="flex items-center text-sm text-huntd-secondary mt-1">
            <Building2 className="w-4 h-4 mr-1" />
            {application.company}
          </div>
        </div>
        <div className="flex flex-col items-end relative">
          <div className="flex items-center gap-2">
            <StatusIcon className={`w-4 h-4 ${statusColor.replace('bg-', 'text-').replace('-100', '-500')}`} />
            <select
              value={application.status}
              onChange={(e) => onStatusChange(application._id, e.target.value)}
              className={`rounded-full px-3 py-1 text-sm font-medium ${statusColor}`}
            >
              <option value="Draft">Draft</option>
              <option value="Applied">Applied</option>
              <option value="Interviewing">Interviewing</option>
              <option value="Offer">Offer</option>
              <option value="Rejected">Rejected</option>
            </select>
            <button
              onClick={() => setShowMenu(!showMenu)}
              className="p-1 hover:bg-huntd-light rounded-full"
            >
              <MoreVertical className="w-4 h-4 text-huntd-secondary" />
            </button>
          </div>

          {showMenu && (
            <div 
              ref={menuRef}
              className="absolute top-8 right-0 mt-2 w-48 rounded-md shadow-soft bg-white ring-1 ring-huntd-primary/10 z-10"
            >
              <div className="py-1">
                <button
                  onClick={handleDelete}
                  className="flex items-center w-full px-4 py-2 text-sm text-huntd-error hover:bg-huntd-error/5"
                >
                  <Trash2 className="w-4 h-4 mr-2" />
                  Delete Application
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {application.jobSpecText && (
        <p className="text-sm text-huntd-secondary mb-4 line-clamp-3">
          {application.jobSpecText}
        </p>
      )}

      <div className="grid grid-cols-2 gap-4 text-sm text-huntd-secondary">
        {application.deadline && (
          <div className="flex items-center">
            <Calendar className="w-4 h-4 mr-2 text-huntd-secondary/70" />
            <span>Due: {formatDate(application.deadline)}</span>
          </div>
        )}
        {application.appliedDate && (
          <div className="flex items-center">
            <Briefcase className="w-4 h-4 mr-2 text-huntd-secondary/70" />
            <span>Applied: {formatDate(application.appliedDate)}</span>
          </div>
        )}
      </div>
    </div>
  );
};

JobApplicationCard.propTypes = {
  application: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    company: PropTypes.string.isRequired,
    position: PropTypes.string.isRequired,
    jobSpecText: PropTypes.string,
    deadline: PropTypes.string,
    appliedDate: PropTypes.string,
    status: PropTypes.oneOf(['Draft', 'Applied', 'Interviewing', 'Offer', 'Rejected']).isRequired
  }).isRequired,
  onStatusChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default JobApplicationCard;
