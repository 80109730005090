import PropTypes from 'prop-types';
import JobApplicationCard from './JobApplicationCard';

const JobApplicationList = ({ applications, onStatusChange }) => {
  // Group applications by status
  const groupedApplications = applications.reduce((acc, app) => {
    if (!acc[app.status]) {
      acc[app.status] = [];
    }
    acc[app.status].push(app);
    return acc;
  }, {});

  // Define status order for display
  const statusOrder = ['Draft', 'Applied', 'Interviewing', 'Offer', 'Rejected'];

  return (
    <div className="space-y-8">
      {statusOrder.map(status => {
        const appsInStatus = groupedApplications[status] || [];
        if (appsInStatus.length === 0) return null;

        return (
          <div key={status}>
            <h2 className="text-lg font-medium text-huntd-primary mb-4">
              {status}
              <span className="ml-2 text-sm text-huntd-secondary">
                ({appsInStatus.length})
              </span>
            </h2>
            <div className="grid grid-cols-1 gap-4">
              {appsInStatus.map(application => (
                <JobApplicationCard
                  key={application._id}
                  application={application}
                  onStatusChange={onStatusChange}
                />
              ))}
            </div>
          </div>
        );
      })}

      {applications.length === 0 && (
        <div className="text-center py-12">
          <p className="text-huntd-secondary">No job applications yet.</p>
          <p className="text-sm text-huntd-secondary/70 mt-1">
            Click &ldquo;Create New Application&rdquo; to get started.
          </p>
        </div>
      )}
    </div>
  );
};

JobApplicationList.propTypes = {
  applications: PropTypes.arrayOf(
    PropTypes.shape({
      _id: PropTypes.string.isRequired,
      company: PropTypes.string.isRequired,
      position: PropTypes.string.isRequired,
      jobSpecText: PropTypes.string,
      deadline: PropTypes.string,
      appliedDate: PropTypes.string,
      status: PropTypes.oneOf(['Draft', 'Applied', 'Interviewing', 'Offer', 'Rejected']).isRequired
    })
  ).isRequired,
  onStatusChange: PropTypes.func.isRequired
};

export default JobApplicationList;
