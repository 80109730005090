// src/components/CVUpload.jsx
import { useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { uploadCVAndExtract, createRole } from '../utils/api';

import { 
  Upload, 
  FileText, 
  CheckCircle, 
  XCircle, 
  AlertCircle,
  Loader, 
  FileUp,
  Brain,
  Database,
  Save,
  Edit2
} from 'lucide-react';
import { 
  PrimaryButton, 
  SecondaryButton,
  Card, 
  CardContent,
  FormInput,
  FormTextArea 
} from './shared/FormStyles';

const ProcessingStep = ({ title, description, status, icon: Icon }) => (
  <div className={`flex items-start p-4 rounded-lg border ${
    status === 'complete' ? 'bg-huntd-success/10 border-huntd-success/20' :
    status === 'error' ? 'bg-huntd-error/10 border-huntd-error/20' :
    status === 'processing' ? 'bg-huntd-info/10 border-huntd-info/20' :
    'bg-huntd-light border-huntd-secondary/20'
  }`}>
    <div className="flex-shrink-0 mt-1">
      {status === 'complete' ? (
        <CheckCircle className="w-5 h-5 text-huntd-success" />
      ) : status === 'error' ? (
        <XCircle className="w-5 h-5 text-huntd-error" />
      ) : status === 'processing' ? (
        <Loader className="w-5 h-5 text-huntd-info animate-spin" />
      ) : (
        <Icon className="w-5 h-5 text-huntd-secondary" />
      )}
    </div>
    <div className="ml-4">
      <h4 className={`text-base font-medium ${
        status === 'complete' ? 'text-huntd-success' :
        status === 'error' ? 'text-huntd-error' :
        status === 'processing' ? 'text-huntd-info' :
        'text-huntd-primary'
      }`}>
        {title}
      </h4>
      <p className={`mt-1 text-base ${
        status === 'complete' ? 'text-huntd-success/80' :
        status === 'error' ? 'text-huntd-error/80' :
        status === 'processing' ? 'text-huntd-info/80' :
        'text-huntd-secondary'
      }`}>
        {description}
      </p>
    </div>
  </div>
);

ProcessingStep.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  status: PropTypes.oneOf(['waiting', 'processing', 'complete', 'error']).isRequired,
  icon: PropTypes.elementType.isRequired
};

const ValidationError = ({ errors }) => (
  <div className="p-4 bg-huntd-error/10 border border-huntd-error/20 rounded-lg">
    <div className="flex items-center mb-2">
      <AlertCircle className="w-5 h-5 text-huntd-error mr-2" />
      <h3 className="text-base font-medium text-huntd-error">
        Validation Errors Found
      </h3>
    </div>
    <ul className="list-disc list-inside text-base text-huntd-error/80 ml-2">
      {errors.map((error, index) => (
        <li key={index}>{error}</li>
      ))}
    </ul>
  </div>
);

ValidationError.propTypes = {
  errors: PropTypes.arrayOf(PropTypes.string).isRequired
};

const ExtractedRoleCard = ({ role, onEdit, onValidate }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedRole, setEditedRole] = useState(role);
  const [validationErrors, setValidationErrors] = useState([]);

  const validateRole = () => {
    const errors = [];
    if (!editedRole.company?.trim()) errors.push("Company name is required");
    if (!editedRole.role?.trim()) errors.push("Role title is required");
    if (!editedRole.startDate?.trim()) errors.push("Start date is required");
    if (!editedRole.description?.trim()) errors.push("Description is required");
    
    const startDate = new Date(editedRole.startDate);
    if (editedRole.endDate && new Date(editedRole.endDate) < startDate) {
      errors.push("End date cannot be before start date");
    }
    
    setValidationErrors(errors);
    return errors.length === 0;
  };

  const handleSave = () => {
    if (validateRole()) {
      onEdit(editedRole);
      setIsEditing(false);
      onValidate(true);
    }
  };

  return (
    <div className="border border-huntd-secondary/20 rounded-lg p-4 bg-white">
      {!isEditing ? (
        <>
          <div className="flex justify-between items-start mb-4">
            <div>
              <h4 className="font-medium text-huntd-primary">{editedRole.role}</h4>
              <p className="text-sm text-huntd-secondary">{editedRole.company}</p>
              <p className="text-sm text-huntd-secondary">
                {editedRole.startDate} - {editedRole.endDate || 'Present'}
              </p>
            </div>
            <SecondaryButton onClick={() => setIsEditing(true)}>
              <Edit2 size={16} className="mr-2" />
              Edit
            </SecondaryButton>
          </div>
          <p className="text-sm text-huntd-secondary/80 mb-3">{editedRole.description}</p>
          {editedRole.skills && (
            <div className="flex flex-wrap gap-2">
              {editedRole.skills.map((skill, i) => (
                <span key={i} className="px-2 py-1 bg-huntd-info/10 text-huntd-info text-xs rounded-full">
                  {skill}
                </span>
              ))}
            </div>
          )}
        </>
      ) : (
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <FormInput
              label="Company"
              value={editedRole.company}
              onChange={(e) => setEditedRole({...editedRole, company: e.target.value})}
              required
            />
            <FormInput
              label="Role"
              value={editedRole.role}
              onChange={(e) => setEditedRole({...editedRole, role: e.target.value})}
              required
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <FormInput
              label="Start Date"
              type="month"
              value={editedRole.startDate}
              onChange={(e) => setEditedRole({...editedRole, startDate: e.target.value})}
              required
            />
            <FormInput
              label="End Date"
              type="month"
              value={editedRole.endDate || ''}
              onChange={(e) => setEditedRole({...editedRole, endDate: e.target.value})}
            />
          </div>
          <FormTextArea
            label="Description"
            value={editedRole.description}
            onChange={(e) => setEditedRole({...editedRole, description: e.target.value})}
            rows={3}
            required
          />
          <FormInput
            label="Skills (comma-separated)"
            value={editedRole.skills.join(', ')}
            onChange={(e) => setEditedRole({
              ...editedRole, 
              skills: e.target.value.split(',').map(s => s.trim()).filter(Boolean)
            })}
          />
          
          {validationErrors.length > 0 && (
            <ValidationError errors={validationErrors} />
          )}

          <div className="flex justify-end gap-2">
            <SecondaryButton onClick={() => setIsEditing(false)}>
              Cancel
            </SecondaryButton>
            <PrimaryButton onClick={handleSave}>
              Save Changes
            </PrimaryButton>
          </div>
        </div>
      )}
    </div>
  );
};

ExtractedRoleCard.propTypes = {
  role: PropTypes.shape({
    company: PropTypes.string,
    role: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    description: PropTypes.string,
    skills: PropTypes.arrayOf(PropTypes.string)
  }).isRequired,
  onEdit: PropTypes.func.isRequired,
  onValidate: PropTypes.func.isRequired
};

const CVUpload = ({ onUploadComplete }) => {
  const navigate = useNavigate();
  const [file, setFile] = useState(null);
  const [processingSteps, setProcessingSteps] = useState({
    upload: 'waiting',    // waiting, processing, complete, error
    extract: 'waiting',   // waiting, processing, complete, error
    aiExtract: 'waiting', // waiting, processing, complete, error
    aiRefine: 'waiting',  // waiting, processing, complete, error
    complete: 'waiting'   // waiting, processing, complete, error
  });
  const [error, setError] = useState(null);
  const [extractedRoles, setExtractedRoles] = useState([]);
  const [validatedRoles, setValidatedRoles] = useState([]);
  const [isCreatingRoles, setIsCreatingRoles] = useState(false);
  const [roleCreationErrors, setRoleCreationErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState(null);

  const validateExtractedData = (data) => {
    const errors = [];
    if (!data.roles || !Array.isArray(data.roles)) {
      errors.push("No valid roles were extracted from the CV");
      return errors;
    }
    
    if (data.roles.length === 0) {
      errors.push("No roles were found in the CV");
      return errors;
    }

    return errors;
  };

  const formatDateForMongoDB = (dateString) => {
    if (!dateString || dateString === 'Present') return null;
    
    // Handle MM/YYYY format
    if (dateString.includes('/')) {
      const [month, year] = dateString.split('/');
      return `${year}-${month.padStart(2, '0')}-01`;
    }
    
    // Handle YYYY-MM format (if it's already in that format)
    if (dateString.includes('-')) {
      return `${dateString}-01`;
    }
    
    return null;
  };

  const createRoles = async () => {
    setIsCreatingRoles(true);
    setRoleCreationErrors([]);
    const errors = [];
  
    try {
      console.log('Starting role creation for:', extractedRoles);
  
      for (const role of extractedRoles) {
        // Format the role data with proper date handling
        const formattedRole = {
          company: role.company,
          role: role.role,
          startDate: formatDateForMongoDB(role.startDate),
          endDate: formatDateForMongoDB(role.endDate),
          isCurrent: !role.endDate || role.endDate === 'Present',
          description: role.description,
          skills: Array.isArray(role.skills) ? role.skills : [],
          achievements: Array.isArray(role.achievements) ? role.achievements : [],
          keywords: Array.isArray(role.skills) ? role.skills : []
          // Don't include userId - let the backend assign it from the authenticated user
        };
  
        console.log('Sending formatted role:', formattedRole);
  
        try {
          await createRole(formattedRole);
          console.log('Role created successfully');
        } catch (error) {
          errors.push(`Failed to create role ${role.role}: ${error.message}`);
          console.error('Role creation failed:', error);
        }
      }
  
      if (errors.length === 0) {
        onUploadComplete({ 
          success: true, 
          rolesCreated: extractedRoles.length 
        });
        setSuccessMessage(`Successfully created ${extractedRoles.length} roles`);
        setExtractedRoles([]);
        setValidatedRoles([]);
        setFile(null);
        
        // Navigate to the admin page after successful role creation
        navigate('/admin');
      } else {
        setRoleCreationErrors(errors);
      }
    } catch (error) {
      console.error('Role creation error:', error);
      setRoleCreationErrors([`Network error: ${error.message}`]);
    } finally {
      setIsCreatingRoles(false);
    }
  };

  const updateStep = (step, status) => {
    setProcessingSteps(prev => ({ ...prev, [step]: status }));
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile && (
      selectedFile.type === 'application/pdf' ||
      selectedFile.type === 'application/msword' ||
      selectedFile.type === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    )) {
      setFile(selectedFile);
      setError(null);
      // Reset steps when new file is selected
      Object.keys(processingSteps).forEach(step => updateStep(step, 'waiting'));
    }
  };

  const handleUpload = async () => {
    if (!file) return;

    try {
      // Upload step
      updateStep('upload', 'processing');
      await new Promise(resolve => setTimeout(resolve, 1500));
      updateStep('upload', 'complete');

      // Text extraction step
      updateStep('extract', 'processing');
      await new Promise(resolve => setTimeout(resolve, 2000));
      updateStep('extract', 'complete');

      // Process CV with progress updates
      const data = await uploadCVAndExtract(file, (stage) => {
        switch (stage) {
          case 'initial_extraction':
            updateStep('aiExtract', 'processing');
            break;
          case 'refinement':
            updateStep('aiExtract', 'complete');
            updateStep('aiRefine', 'processing');
            break;
          case 'complete':
            updateStep('aiRefine', 'complete');
            break;
        }
      });
      
      const validationErrors = validateExtractedData(data);
      if (validationErrors.length > 0) {
        setError(validationErrors.join('. '));
        updateStep('aiExtract', 'error');
        updateStep('aiRefine', 'error');
        return;
      }

      // Complete step
      updateStep('complete', 'complete');
      setExtractedRoles(data.roles);
      setValidatedRoles(new Array(data.roles.length).fill(false));
    } catch (error) {
      console.error('Upload error:', error);
      setError(error.message);
      Object.keys(processingSteps).forEach(step => {
        if (processingSteps[step] === 'processing') {
          updateStep(step, 'error');
        }
      });
    }
  };

  return (
    <div className="space-y-6">
      <Card>
        <div className="border-b border-huntd-secondary/10 bg-white px-6 py-4">
          <h3 className="text-2xl font-bold text-huntd-primary">Upload CV</h3>
          <p className="mt-1 text-sm text-huntd-secondary">Upload your CV to automatically extract role information</p>
        </div>
        <CardContent>
          <div className="space-y-6">
            {/* Upload Area - Only show if no file is selected */}
            {!file && (
              <div className="border-2 border-dashed border-huntd-secondary/30 rounded-lg p-8 text-center hover:border-huntd-secondary/50 transition-colors">
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  onChange={handleFileChange}
                  className="hidden"
                  id="cv-upload"
                />
                <label 
                  htmlFor="cv-upload"
                  className="cursor-pointer flex flex-col items-center"
                >
                  <FileUp size={40} className="text-huntd-secondary/50 mb-4" />
                  <span className="text-base font-medium text-huntd-primary">
                    Drop your CV here or click to browse
                  </span>
                  <span className="text-sm text-huntd-secondary mt-2">
                    Supports PDF, DOC, and DOCX files
                  </span>
                </label>
              </div>
            )}

            {/* Selected File */}
            {file && (
              <div className={`flex items-center justify-between p-4 ${processingSteps.complete === 'complete' ? 'bg-huntd-success/10 border-huntd-success/20' : 'bg-white border-huntd-secondary/20'} border rounded-lg`}>
                <div className="flex items-center">
                  {processingSteps.complete === 'complete' ? (
                    <CheckCircle size={20} className="text-huntd-success mr-2" />
                  ) : (
                    <FileText size={20} className="text-huntd-secondary mr-2" />
                  )}
                  <div>
                    <p className={`text-base font-medium ${processingSteps.complete === 'complete' ? 'text-huntd-success' : 'text-huntd-primary'}`}>{file.name}</p>
                    <p className="text-sm text-huntd-secondary">
                      {(file.size / 1024 / 1024).toFixed(2)} MB
                    </p>
                  </div>
                </div>
                <div className="flex gap-2">
                  <SecondaryButton
                    onClick={() => {
                      setFile(null);
                      // Reset processing steps
                      Object.keys(processingSteps).forEach(step => updateStep(step, 'waiting'));
                    }}
                  >
                    Change File
                  </SecondaryButton>
                  {processingSteps.complete !== 'complete' && (
                    <PrimaryButton
                      onClick={handleUpload}
                      disabled={Object.values(processingSteps).some(step => step === 'processing')}
                    >
                      <Upload size={16} className="mr-2" />
                      Process CV
                    </PrimaryButton>
                  )}
                </div>
              </div>
            )}

            {/* Processing Steps - Only show if file is selected and process is not complete */}
            {file && processingSteps.complete !== 'complete' && (
              <div className="space-y-3">
                <ProcessingStep
                  title="Upload CV"
                  description="Uploading your CV file to the server"
                  status={processingSteps.upload}
                  icon={FileUp}
                />
                <ProcessingStep
                  title="Extract Content"
                  description="Extracting text content from your CV"
                  status={processingSteps.extract}
                  icon={FileText}
                />
                <ProcessingStep
                  title="Initial AI Extraction"
                  description="Extracting roles and experience from CV content"
                  status={processingSteps.aiExtract}
                  icon={Brain}
                />
                <ProcessingStep
                  title="AI Refinement"
                  description="Enhancing and standardizing extracted information"
                  status={processingSteps.aiRefine}
                  icon={Edit2}
                />
                <ProcessingStep
                  title="Process Complete"
                  description="Preparing extracted data for review"
                  status={processingSteps.complete}
                  icon={Database}
                />
              </div>
            )}

            {/* Error Message */}
            {error && (
              <div className="p-4 bg-huntd-error/10 border border-huntd-error/20 rounded-lg flex items-start">
                <AlertCircle className="w-5 h-5 text-huntd-error mt-0.5" />
                <div className="ml-3">
                  <h3 className="text-base font-medium text-huntd-error">
                    Error Processing CV
                  </h3>
                  <p className="mt-1 text-base text-huntd-error/80">{error}</p>
                </div>
              </div>
            )}
          </div>
        </CardContent>
      </Card>

      {/* Validate Extracted Roles */}
      {extractedRoles.length > 0 && (
        <Card>
          <div className="border-b border-huntd-secondary/10 bg-white px-6 py-4">
            <div className="flex justify-between items-center">
              <div>
                <h3 className="text-2xl font-bold text-huntd-primary">Validate Extracted Roles</h3>
                <p className="mt-1 text-sm text-huntd-secondary">Review and edit the extracted roles before creating them</p>
              </div>
              <PrimaryButton
                onClick={createRoles}
                disabled={isCreatingRoles}
                className="flex items-center"
              >
                {isCreatingRoles ? (    
                  <>
                    <Loader className="animate-spin -ml-1 mr-2 h-5 w-5" />
                    Creating Roles...
                  </>
                ) : (
                  <>
                    <Save className="w-5 h-5 mr-2" />
                    Create Roles
                  </>
                )}
              </PrimaryButton>
            </div>
          </div>
          <CardContent>
            <div className="space-y-6">
              {extractedRoles.map((role, index) => (
                <ExtractedRoleCard
                  key={index}
                  role={role}
                  onEdit={(updatedRole) => {
                    const newRoles = [...extractedRoles];
                    newRoles[index] = updatedRole;
                    setExtractedRoles(newRoles);
                  }}
                  onValidate={(isValid) => {
                    const newValidated = [...validatedRoles];
                    newValidated[index] = isValid;
                    setValidatedRoles(newValidated);
                  }}
                />
              ))}

              {roleCreationErrors.length > 0 && (
                <ValidationError errors={roleCreationErrors} />
              )}

              {/* Removed the bottom Create Roles button since it's now at the top */}
            </div>
          </CardContent>
        </Card>
      )}

      {/* Success Message */}
      {successMessage && (
        <div className="p-4 bg-huntd-success/10 border border-huntd-success/20 rounded-lg flex items-center">
          <CheckCircle className="w-5 h-5 text-huntd-success mr-2" />
          <span className="text-huntd-success">{successMessage}</span>
        </div>
      )}

      {/* Error Messages */}
      {roleCreationErrors.length > 0 && (
        <div className="mt-4 p-4 bg-huntd-error/10 border border-huntd-error/20 rounded-lg">
              <div className="flex items-center mb-2">
                <AlertCircle className="w-5 h-5 text-huntd-error mr-2" />
                <h3 className="text-base font-medium text-huntd-error">
                  Errors occurred while creating roles
                </h3>
              </div>
              <ul className="list-disc list-inside text-base text-huntd-error/80">
            {roleCreationErrors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

CVUpload.propTypes = {
  onUploadComplete: PropTypes.func.isRequired
};

export default CVUpload;
