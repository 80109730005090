// fetch-with-auth.js
import { API_BASE_URL } from '../config';
import monitoringService from '../services/monitoring';

// CORS error detection
const isCorsError = (error) => {
  return (
    error.message.includes('CORS') ||
    error.message.includes('Cross-Origin') ||
    error.message.includes('Access-Control-Allow-Origin')
  );
};

// Enhanced fetch wrapper with improved auth token handling, verification, and monitoring
async function fetchWithAuth(url, options = {}) {
  const startTime = Date.now();
  const requestId = `req-${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
  
  // Track API call start
  monitoringService.trackEvent('ApiCallStarted', {
    url: url.replace(API_BASE_URL, ''),
    method: options.method || 'GET',
    requestId
  });

  // Add origin header for CORS debugging
  const corsHeaders = {
    'Origin': window.location.origin
  };

  // Get SWA auth data with error handling
  let authData;
  try {
    const authResponse = await fetch('/.auth/me');
    if (!authResponse.ok) {
      console.error('Failed to fetch auth data:', authResponse.statusText);
      throw new Error('Authentication service unavailable');
    }
    authData = await authResponse.json();
  } catch (error) {
    console.error('Auth data fetch error:', error);
    throw new Error('Authentication service error');
  }
  
  // Don't set any default headers if we're sending FormData
  const isFormData = options.body instanceof FormData;
  
  const defaultOptions = {
    headers: isFormData ? corsHeaders : {
      'Content-Type': 'application/json',
      ...corsHeaders,
      ...options.headers,
    },
  };

  // Add SWA headers if available with validation
  if (authData.clientPrincipal) {
    if (!authData.clientPrincipal.userId || !authData.clientPrincipal.userDetails) {
      console.error('Invalid client principal data:', authData.clientPrincipal);
      throw new Error('Invalid authentication data');
    }
    
    Object.assign(defaultOptions.headers, {
      'X-MS-CLIENT-PRINCIPAL-ID': authData.clientPrincipal.userId,
      'X-MS-CLIENT-PRINCIPAL-NAME': authData.clientPrincipal.userDetails,
      'X-MS-TOKEN': authData.clientPrincipal.token || 'no-token-provided'
    });
    
    const requestId = `${Date.now()}-${Math.random().toString(36).substring(2, 10)}`;
    Object.assign(defaultOptions.headers, {
      'X-Request-ID': requestId
    });
    
    monitoringService.setOperationId(requestId);
  }

  try {
    // For FormData, don't override headers at all
    const requestOptions = isFormData ? {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers
      }
    } : {
      ...defaultOptions,
      ...options,
      headers: {
        ...defaultOptions.headers,
        ...options.headers,
      }
    };

    // Track dependency call
    const dependencyStartTime = Date.now();
    const response = await fetch(url, requestOptions);
    const dependencyDuration = Date.now() - dependencyStartTime;
    
    // Track dependency call as a metric
    monitoringService.trackMetric('ApiDependencyDuration', dependencyDuration, {
      url: url.replace(API_BASE_URL, ''),
      method: options.method || 'GET'
    });
    
    // Check for correlation ID in response headers
    const correlationId = response.headers.get('x-ms-request-id');
    if (correlationId) {
      monitoringService.setOperationId(correlationId);
    }

    // Enhanced error handling with CORS detection and retry
    if (!response.ok) {
      const errorData = await response.text();
      let parsedError;
      try {
        parsedError = JSON.parse(errorData);
      } catch {
        parsedError = { message: errorData };
      }

      // Check if this might be a CORS error
      if (response.status === 0 || response.type === 'opaque' || 
          (response.status === 403 && parsedError.message?.includes('CORS'))) {
        
        // Log CORS error details
        console.error('Possible CORS error:', {
          status: response.status,
          type: response.type,
          url,
          origin: window.location.origin,
          error: parsedError,
          headers: response.headers
        });

        // Try to get more details about the CORS configuration
        try {
          const healthCheck = await fetch(`${API_BASE_URL}/api/health`, {
            headers: { 'Origin': window.location.origin }
          });
          const healthData = await healthCheck.json();
          console.error('CORS Configuration:', healthData.cors);
        } catch (healthError) {
          console.error('Failed to fetch CORS configuration:', healthError);
        }

        throw new Error('CORS error: Unable to access the API. Check browser console for details.');
      }

      throw new Error(parsedError.message || `API request failed: ${response.statusText}`);
    }

    const data = await response.json();
    
    // Track API call completion
    const duration = Date.now() - startTime;
    monitoringService.trackEvent('ApiCallCompleted', {
      url: url.replace(API_BASE_URL, ''),
      method: options.method || 'GET',
      statusCode: response.status,
      duration,
      requestId
    });
    
    monitoringService.trackMetric('ApiCallDuration', duration, {
      url: url.replace(API_BASE_URL, ''),
      method: options.method || 'GET',
      statusCode: response.status,
      success: true
    });
    
    return data;
  } catch (error) {
    // Track API call error with enhanced CORS detection
    const duration = Date.now() - startTime;
    const errorDetails = {
      url: url.replace(API_BASE_URL, ''),
      method: options.method || 'GET',
      duration,
      requestId,
      isCorsError: isCorsError(error),
      origin: window.location.origin
    };

    monitoringService.trackException(error, errorDetails);
    
    monitoringService.trackMetric('ApiCallDuration', duration, {
      ...errorDetails,
      success: false,
      errorType: error.name,
      errorMessage: error.message
    });

    // Special handling for CORS errors
    if (isCorsError(error)) {
      console.error('CORS Error Details:', {
        ...errorDetails,
        error: error.message
      });
      throw new Error(`CORS configuration error: ${error.message}`);
    }
    
    throw error;
  }
}

export default fetchWithAuth;
