// src/components/RoleSection.jsx
import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { 
  PlusCircle, 
  Trash2, 
  Edit,
  Upload
} from 'lucide-react';
import { 
  PrimaryButton, 
  SecondaryButton, 
  DangerButton,
  FormInput,
  FormTextArea,
  Card,
  CardHeader,
  CardContent 
} from './shared/FormStyles';
import { getRoles, getSkills, createRole, updateRole, deleteRole } from '../utils/api';

const SkillSelector = ({ selectedSkills, availableSkills, existingSkills, onChange }) => {
  return (
    <div className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Skills from Library</label>
        <select
          multiple
          className="mt-1 block w-full py-2 px-3 border border-gray-300 rounded-md shadow-sm"
          value={selectedSkills}
          onChange={(e) => {
            const values = Array.from(e.target.selectedOptions).map(option => option.value);
            onChange(values);
          }}
        >
          {availableSkills.map(skill => (
            <option key={skill._id} value={skill._id}>
              {skill.name} ({skill.category})
            </option>
          ))}
        </select>
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Additional Skills</label>
        <input
          type="text"
          value={existingSkills}
          onChange={(e) => onChange(e.target.value, 'manual')}
          className="mt-1 block w-full shadow-sm sm:text-sm border-gray-300 rounded-md"
          placeholder="Comma-separated skills not in library"
        />
      </div>
    </div>
  );
};

SkillSelector.propTypes = {
  selectedSkills: PropTypes.arrayOf(PropTypes.string).isRequired,
  availableSkills: PropTypes.arrayOf(PropTypes.shape({
    _id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    category: PropTypes.string
  })).isRequired,
  existingSkills: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

const RoleSection = () => {
  const [roles, setRoles] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [availableSkills, setAvailableSkills] = useState([]);
  const [formData, setFormData] = useState({
    company: '',
    role: '',
    startDate: '',
    endDate: '',
    isCurrent: false,
    description: '',
    skills: '',
    linkedSkills: [],
    achievements: '',
    keywords: ''
  });

  useEffect(() => {
    fetchRoles();
    fetchSkills();
  }, []);

  const fetchRoles = async () => {
    try {
      const data = await getRoles();
      setRoles(data);
    } catch (error) {
      console.error('Error fetching roles:', error);
      setError('Failed to fetch roles');
    }
  };

  const fetchSkills = async () => {
    try {
      const data = await getSkills();
      setAvailableSkills(data);
    } catch (error) {
      console.error('Error fetching skills:', error);
      setError('Failed to fetch skills');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setError(null);

    try {
      const formattedData = {
        ...formData,
        skills: formData.skills.split(',').map(s => s.trim()).filter(Boolean),
        achievements: formData.achievements.split(',').map(a => a.trim()).filter(Boolean),
        keywords: formData.keywords.split(',').map(k => k.trim()).filter(Boolean)
      };

      if (editingId) {
        await updateRole(editingId, formattedData);
      } else {
        await createRole(formattedData);
      }
      
      await fetchRoles();
      resetForm();
    } catch (error) {
      console.error('Error saving role:', error);
      setError('Failed to save role');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this role?')) return;

    setIsLoading(true);
    setError(null);

    try {
      await deleteRole(id);
      await fetchRoles();
    } catch (error) {
      console.error('Error deleting role:', error);
      setError('Failed to delete role');
    } finally {
      setIsLoading(false);
    }
  };

  const handleEdit = (role) => {
    setEditingId(role._id);
    setFormData({
      company: role.company,
      role: role.role,
      startDate: role.startDate.substring(0, 7),
      endDate: role.endDate ? role.endDate.substring(0, 7) : '',
      isCurrent: role.isCurrent,
      description: role.description,
      skills: Array.isArray(role.skills) ? role.skills.join(', ') : '',
      linkedSkills: role.linkedSkills || [],
      achievements: Array.isArray(role.achievements) ? role.achievements.join(', ') : '',
      keywords: Array.isArray(role.keywords) ? role.keywords.join(', ') : ''
    });
    setIsAdding(true);
  };

  const resetForm = () => {
    setFormData({
      company: '',
      role: '',
      startDate: '',
      endDate: '',
      isCurrent: false,
      description: '',
      skills: '',
      linkedSkills: [],
      achievements: '',
      keywords: ''
    });
    setEditingId(null);
    setIsAdding(false);
  };

  return (
    <div className="space-y-6">
      {/* Error Display */}
      {error && (
        <div className="bg-red-50 border border-red-200 text-red-700 px-4 py-3 rounded relative">
          {error}
        </div>
      )}

      {/* Add Button and Import from CV Button */}
      {!isAdding && (
        <div className="flex justify-between items-center">
          <PrimaryButton
            onClick={() => setIsAdding(true)}
            className="flex items-center"
          >
            <PlusCircle size={20} className="mr-2" />
            Add New Role
          </PrimaryButton>
          
          <PrimaryButton
            onClick={() => window.location.href = '/cv-upload'}
            className="flex items-center"
          >
            <Upload size={20} className="mr-2" />
            Import from CV
          </PrimaryButton>
        </div>
      )}

      {/* Add/Edit Form */}
      {isAdding && (
        <Card className="mb-6">
          <CardHeader 
            title={editingId ? "Edit Role" : "Add New Role"}
            subtitle="Enter the role details"
          />
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-2 gap-4">
                <FormInput
                  label="Company"
                  required
                  value={formData.company}
                  onChange={(e) => setFormData({...formData, company: e.target.value})}
                  placeholder="Company name"
                />
                <FormInput
                  label="Role"
                  required
                  value={formData.role}
                  onChange={(e) => setFormData({...formData, role: e.target.value})}
                  placeholder="Job title"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <FormInput
                    label="Start Date"
                    required
                    type="month"
                    value={formData.startDate}
                    onChange={(e) => setFormData({...formData, startDate: e.target.value})}
                  />
                </div>
                <div>
                  <FormInput
                    label="End Date"
                    type="month"
                    value={formData.endDate}
                    onChange={(e) => setFormData({...formData, endDate: e.target.value})}
                    disabled={formData.isCurrent}
                  />
                  <div className="mt-2 flex items-center">
                    <input
                      type="checkbox"
                      id="isCurrent"
                      checked={formData.isCurrent}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          isCurrent: e.target.checked,
                          endDate: e.target.checked ? '' : formData.endDate
                        });
                      }}
                      className="rounded border-gray-300 text-blue-600 shadow-sm focus:border-blue-300 focus:ring focus:ring-blue-200 focus:ring-opacity-50"
                    />
                    <label htmlFor="isCurrent" className="ml-2 text-sm text-gray-600">
                      This is my current role
                    </label>
                  </div>
                </div>
              </div>

              <FormTextArea
                label="Description"
                required
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
                rows={4}
                placeholder="Describe your role and responsibilities"
              />

              <FormInput
                label="Achievements (comma-separated)"
                value={formData.achievements}
                onChange={(e) => setFormData({...formData, achievements: e.target.value})}
                placeholder="Key achievements in this role"
              />

              <FormInput
                label="Keywords (comma-separated)"
                value={formData.keywords}
                onChange={(e) => setFormData({...formData, keywords: e.target.value})}
                placeholder="Relevant keywords"
              />
              
              {/* Moved SkillSelector to the bottom */}
              <div className="border-t border-gray-200 pt-4 mt-4">
                <h4 className="text-base font-medium text-gray-900 mb-4">Skills</h4>
                <SkillSelector
                  selectedSkills={formData.linkedSkills}
                  availableSkills={availableSkills}
                  existingSkills={formData.skills}
                  onChange={(value, type) => {
                    if (type === 'manual') {
                      setFormData(prev => ({ ...prev, skills: value }));
                    } else {
                      setFormData(prev => ({ ...prev, linkedSkills: value }));
                    }
                  }}
                />
              </div>

              <div className="flex justify-end gap-3">
                <SecondaryButton type="button" onClick={resetForm}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton type="submit" disabled={isLoading}>
                  {isLoading ? 'Saving...' : editingId ? 'Update' : 'Save'} Role
                </PrimaryButton>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      {/* Empty State Message */}
      {roles.length === 0 && !isAdding && (
        <div className="text-center py-12 bg-white rounded-lg shadow-sm">
          <h3 className="text-xl font-medium text-gray-700 mb-2">No experience data yet</h3>
          <p className="text-gray-500 mb-6">To get started, add a role or upload your CV.</p>
        </div>
      )}

      {/* Roles List */}
      <div className="space-y-6">
        {roles.map((role) => (
          <Card key={role._id}>
            <CardContent>
              <div className="flex justify-between items-start">
                <div>
                  <div className="flex items-center gap-2">
                    <h3 className="text-lg font-semibold text-gray-900">
                      {role.company}
                    </h3>
                    {role.isCurrent && (
                      <span className="inline-flex items-center px-2 py-1 text-xs font-medium bg-green-100 text-green-800 rounded-full">
                        Current Role
                      </span>
                    )}
                  </div>
                  <p className="text-md text-gray-700 mt-1">{role.role}</p>
                  <p className="text-sm text-gray-500">
                    {new Date(role.startDate).toLocaleDateString('en-US', { 
                      month: 'long', 
                      year: 'numeric' 
                    })} - {role.isCurrent ? 'Present' : role.endDate ? 
                      new Date(role.endDate).toLocaleDateString('en-US', { 
                        month: 'long', 
                        year: 'numeric' 
                      }) : 'Present'}
                  </p>
                </div>
                
                <div className="flex gap-2">
                  <SecondaryButton onClick={() => handleEdit(role)}>
                    <Edit size={16} className="mr-1" />
                    Edit
                  </SecondaryButton>
                  <DangerButton 
                    onClick={() => handleDelete(role._id)}
                    disabled={isLoading}
                  >
                    <Trash2 size={16} className="mr-1" />
                    Delete
                  </DangerButton>
                </div>
              </div>

              {role.description && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-900">Description</h4>
                  <p className="mt-1 text-sm text-gray-600 whitespace-pre-line">
                    {role.description}
                  </p>
                </div>
              )}

              <div className="mt-4">
                <h4 className="text-sm font-medium text-gray-900">Skills</h4>
                <div className="mt-2">
                  {role.linkedSkills?.length > 0 && (
                    <div className="mb-2">
                      <p className="text-xs text-gray-500 mb-1">Verified Skills</p>
                      <div className="flex flex-wrap gap-2">
                        {role.linkedSkills.map(skillId => {
                          const skill = availableSkills.find(s => s._id === skillId);
                          if (!skill) return null;
                          return (
                            <span
                              key={skillId}
                              className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-blue-100 text-blue-800"
                              title={skill.description}
                            >
                              {skill.name}
                              {skill.category && (
                                <span className="ml-1 text-blue-600">• {skill.category}</span>
                              )}
                            </span>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  
                  {role.skills?.length > 0 && (
                    <div>
                      <p className="text-xs text-gray-500 mb-1">Additional Skills</p>
                      <div className="flex flex-wrap gap-2">
                        {role.skills.map((skill, index) => (
                          <span
                            key={index}
                            className="inline-flex items-center px-2.5 py-1 rounded-full text-xs font-medium bg-gray-100 text-gray-800"
                          >
                            {skill}
                          </span>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {role.achievements?.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-900">Key Achievements</h4>
                  <ul className="mt-2 list-disc list-inside text-sm text-gray-600">
                    {role.achievements.map((achievement, index) => (
                      <li key={index} className="ml-2">{achievement}</li>
                    ))}
                  </ul>
                </div>
              )}

              {role.keywords?.length > 0 && (
                <div className="mt-4">
                  <h4 className="text-sm font-medium text-gray-900">Keywords</h4>
                  <div className="mt-2 flex flex-wrap gap-2">
                    {role.keywords.map((keyword, index) => (
                      <span
                        key={index}
                        className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-50 text-gray-600"
                      >
                        {keyword}
                      </span>
                    ))}
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default RoleSection;
