import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Save, X, AlertCircle } from 'lucide-react';
import { createJobApplication } from '../utils/api';

const JobApplicationForm = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    company: '',
    position: '',
    jobSpecText: '',
    deadline: '',
    status: 'Draft',
    appliedDate: ''
  });

  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      await createJobApplication(formData);
      handleClose(true);
    } catch (err) {
      console.error('Error creating job application:', err);
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClose = (refresh = false) => {
    navigate('/', { state: { refresh } });
  };

  // Close on escape key
  useEffect(() => {
    const handleEscape = (e) => {
      if (e.key === 'Escape') {
        handleClose();
      }
    };
    window.addEventListener('keydown', handleEscape);
    return () => window.removeEventListener('keydown', handleEscape);
  }, []);

  return (
    <div className="max-w-2xl mx-auto">
      <div className="bg-white shadow rounded-lg p-6">
        <div className="flex justify-between items-center mb-6">
          <h2 className="text-xl font-semibold text-huntd-primary">New Job Application</h2>
          <button
            onClick={() => handleClose()}
            className="text-huntd-secondary hover:text-huntd-primary"
          >
            <X className="h-5 w-5" />
          </button>
        </div>

        {error && (
          <div className="mb-6 bg-huntd-error/10 border border-huntd-error/20 text-huntd-error px-4 py-3 rounded relative flex items-start">
            <AlertCircle className="w-5 h-5 mr-2 mt-0.5" />
            <span>{error}</span>
          </div>
        )}

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-huntd-primary">
              Company Name
            </label>
            <input
              type="text"
              value={formData.company}
              onChange={(e) => setFormData({ ...formData, company: e.target.value })}
              className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-huntd-primary">
              Position
            </label>
            <input
              type="text"
              value={formData.position}
              onChange={(e) => setFormData({ ...formData, position: e.target.value })}
              className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
              required
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-huntd-primary">
              Job Description
            </label>
            <textarea
              value={formData.jobSpecText}
              onChange={(e) => setFormData({ ...formData, jobSpecText: e.target.value })}
              rows={4}
              className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-huntd-primary">
              Application Deadline
            </label>
            <input
              type="date"
              value={formData.deadline}
              onChange={(e) => setFormData({ ...formData, deadline: e.target.value })}
              className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-huntd-primary">
              Status
            </label>
            <select
              value={formData.status}
              onChange={(e) => {
                const newStatus = e.target.value;
                setFormData(prev => ({
                  ...prev,
                  status: newStatus,
                  appliedDate: newStatus === 'Applied' ? new Date().toISOString().split('T')[0] : prev.appliedDate
                }));
              }}
              className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
            >
              <option value="Draft">Draft</option>
              <option value="Applied">Applied</option>
              <option value="Interviewing">Interviewing</option>
              <option value="Offer">Offer</option>
              <option value="Rejected">Rejected</option>
            </select>
          </div>

          {formData.status === 'Applied' && (
            <div>
              <label className="block text-sm font-medium text-huntd-primary">
                Applied Date
              </label>
              <input
                type="date"
                value={formData.appliedDate}
                onChange={(e) => setFormData({ ...formData, appliedDate: e.target.value })}
                className="mt-1 block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm"
                required
              />
            </div>
          )}

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => handleClose()}
              className="inline-flex items-center px-4 py-2 border border-huntd-secondary/30 shadow-sm text-sm font-medium rounded-md text-huntd-primary bg-white hover:bg-huntd-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-huntd-info"
            >
              <X className="h-4 w-4 mr-2" />
              Cancel
            </button>
            <button
              type="submit"
              disabled={isSubmitting}
              className={`inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white ${
                isSubmitting 
                  ? 'bg-huntd-info/50 cursor-not-allowed' 
                  : 'bg-huntd-info hover:bg-huntd-info/80 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-huntd-info'
              }`}
            >
              <Save className="h-4 w-4 mr-2" />
              {isSubmitting ? 'Saving...' : 'Save Application'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default JobApplicationForm;
