const config = {
  apiUrl: import.meta.env.VITE_API_URL,
  API_BASE_URL: import.meta.env.VITE_API_URL,
  isDevelopment: import.meta.env.MODE === 'development',

  // Validate required environment variables
  validateConfig() {
    if (!this.apiUrl || !this.API_BASE_URL) {
      throw new Error('VITE_API_URL environment variable must be set');
    }
    // Log the configuration for debugging
    if (this.isDevelopment) {
      console.log('Frontend Configuration:', {
        apiUrl: this.apiUrl,
        isDevelopment: this.isDevelopment
      });
    }
  },
  monitoring: {
    applicationInsights: {
      connectionString: import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTION_STRING || null,
      enabled: !!import.meta.env.VITE_APPLICATIONINSIGHTS_CONNECTION_STRING
    }
  }
};

export default config;
export const { API_BASE_URL, isDevelopment } = config;
