import PropTypes from 'prop-types';

// src/components/shared/ButtonStyles.jsx
export const PrimaryButton = ({ children, ...props }) => (
    <button
      {...props}
      className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-huntd-info hover:bg-huntd-info/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-huntd-info transition-colors duration-150"
    >
      {children}
    </button>
  );
  
  PrimaryButton.propTypes = {
    children: PropTypes.node.isRequired
  };

  export const SecondaryButton = ({ children, ...props }) => (
    <button
      {...props}
      className="inline-flex items-center justify-center px-4 py-2 border border-huntd-secondary/30 rounded-md shadow-sm text-sm font-medium text-huntd-primary bg-white hover:bg-huntd-light focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-huntd-info transition-colors duration-150"
    >
      {children}
    </button>
  );
  
  SecondaryButton.propTypes = {
    children: PropTypes.node.isRequired
  };

  export const DangerButton = ({ children, ...props }) => (
    <button
      {...props}
      className="inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-huntd-error hover:bg-huntd-error/90 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-huntd-error transition-colors duration-150"
    >
      {children}
    </button>
  );
  
  DangerButton.propTypes = {
    children: PropTypes.node.isRequired
  };

  // src/components/shared/FormStyles.jsx
  export const FormInput = ({ label, required, ...props }) => (
    <div>
      <label className="block text-sm font-medium text-huntd-primary mb-1">
        {label}
        {required && <span className="text-huntd-error ml-1">*</span>}
      </label>
      <input
        {...props}
        className="block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm transition-colors duration-150"
      />
    </div>
  );
  
  FormInput.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool
  };

  export const FormTextArea = ({ label, required, ...props }) => (
    <div>
      <label className="block text-sm font-medium text-huntd-primary mb-1">
        {label}
        {required && <span className="text-huntd-error ml-1">*</span>}
      </label>
      <textarea
        {...props}
        className="block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm transition-colors duration-150"
      />
    </div>
  );
  
  FormTextArea.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool
  };

  export const FormSelect = ({ label, required, children, ...props }) => (
    <div>
      <label className="block text-sm font-medium text-huntd-primary mb-1">
        {label}
        {required && <span className="text-huntd-error ml-1">*</span>}
      </label>
      <select
        {...props}
        className="block w-full rounded-md border-huntd-secondary/30 shadow-sm focus:border-huntd-info focus:ring-huntd-info sm:text-sm transition-colors duration-150"
      >
        {children}
      </select>
    </div>
  );
  
  FormSelect.propTypes = {
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    children: PropTypes.node
  };

  export const Card = ({ children, className = "" }) => (
    <div className={`bg-white rounded-lg shadow-soft border border-huntd-secondary/10 overflow-hidden ${className}`}>
      {children}
    </div>
  );
  
  Card.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node.isRequired
  };

  export const CardHeader = ({ title, subtitle }) => (
    <div className="border-b border-huntd-secondary/10 bg-white px-6 py-4">
      <h3 className="text-lg font-medium text-huntd-primary">{title}</h3>
      {subtitle && <p className="mt-1 text-sm text-huntd-secondary">{subtitle}</p>}
    </div>
  );
  
  CardHeader.propTypes = {
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string
  };

  export const CardContent = ({ children }) => (
    <div className="px-6 py-4">{children}</div>
  );
  
  CardContent.propTypes = {
    children: PropTypes.node.isRequired
  };
