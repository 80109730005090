import { useState, useEffect } from 'react';
import { 
  PlusCircle, 
  Trash2, 
  Edit,
  Search 
} from 'lucide-react';
import { 
  PrimaryButton, 
  SecondaryButton, 
  DangerButton,
  FormInput,
  FormSelect,
  Card,
  CardHeader,
  CardContent 
} from './shared/FormStyles';
import { getSkills, createSkill, updateSkill, deleteSkill } from '../utils/api';

const SkillsSection = () => {
  const [skills, setSkills] = useState([]);
  const [editingId, setEditingId] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [formData, setFormData] = useState({
    name: '',
    category: '',
    description: '',
    aliases: '',
    isCommon: false
  });

  useEffect(() => {
    fetchSkills();
  }, []);

  const fetchSkills = async () => {
    try {
      const data = await getSkills();
      setSkills(data || []);
    } catch (error) {
      console.error('Error fetching skills:', error);
      setSkills([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formatted = {
        ...formData,
        aliases: formData.aliases.split(',').map(a => a.trim()).filter(Boolean)
      };

      if (editingId) {
        await updateSkill(editingId, formatted);
      } else {
        await createSkill(formatted);
      }

      fetchSkills();
      resetForm();
    } catch (error) {
      console.error('Error saving skill:', error);
    }
  };

  const handleDelete = async (id) => {
    if (!window.confirm('Are you sure you want to delete this skill?')) return;

    try {
      await deleteSkill(id);
      fetchSkills();
    } catch (error) {
      console.error('Error deleting skill:', error);
    }
  };

  const handleEdit = (skill) => {
    setEditingId(skill._id);
    setFormData({
      name: skill.name,
      category: skill.category,
      description: skill.description || '',
      aliases: skill.aliases?.join(', ') || '',
      isCommon: skill.isCommon
    });
    setIsAdding(true);
  };

  const resetForm = () => {
    setFormData({
      name: '',
      category: '',
      description: '',
      aliases: '',
      isCommon: false
    });
    setEditingId(null);
    setIsAdding(false);
  };

  const filteredSkills = skills.filter(skill => 
    skill.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
    (skill.aliases || []).some(alias => alias.toLowerCase().includes(searchTerm.toLowerCase())) ||
    skill.category.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div className="space-y-6">
      {/* Search and Add */}
      <div className="flex justify-between items-center">
        <div className="relative w-64">
          <input
            type="text"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            placeholder="Search skills..."
            className="w-full pl-10 pr-4 py-2 border rounded-lg"
          />
          <Search className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
        </div>
        
        {!isAdding && (
          <PrimaryButton onClick={() => setIsAdding(true)}>
            <PlusCircle size={20} className="mr-2" />
            Add New Skill
          </PrimaryButton>
        )}
      </div>

      {/* Add/Edit Form */}
      {isAdding && (
        <Card>
          <CardHeader 
            title={editingId ? "Edit Skill" : "Add New Skill"}
            subtitle="Enter the skill details"
          />
          <CardContent>
            <form onSubmit={handleSubmit} className="space-y-4">
              <FormInput
                label="Skill Name"
                required
                value={formData.name}
                onChange={(e) => setFormData({...formData, name: e.target.value})}
              />

              <FormSelect
                label="Category"
                required
                value={formData.category}
                onChange={(e) => setFormData({...formData, category: e.target.value})}
              >
                <option value="">Select category...</option>
                <option value="Technical">Technical</option>
                <option value="Soft">Soft</option>
                <option value="Domain">Domain</option>
                <option value="Tool">Tool</option>
                <option value="Platform">Platform</option>
              </FormSelect>

              <FormInput
                label="Description"
                value={formData.description}
                onChange={(e) => setFormData({...formData, description: e.target.value})}
              />

              <FormInput
                label="Aliases (comma-separated)"
                value={formData.aliases}
                onChange={(e) => setFormData({...formData, aliases: e.target.value})}
                placeholder="e.g., React.js, ReactJS"
              />

              <div className="flex items-center">
                <input
                  type="checkbox"
                  id="isCommon"
                  checked={formData.isCommon}
                  onChange={(e) => setFormData({...formData, isCommon: e.target.checked})}
                  className="mr-2"
                />
                <label htmlFor="isCommon">Commonly used skill</label>
              </div>

              <div className="flex justify-end gap-2">
                <SecondaryButton type="button" onClick={resetForm}>
                  Cancel
                </SecondaryButton>
                <PrimaryButton type="submit">
                  {editingId ? 'Update' : 'Save'} Skill
                </PrimaryButton>
              </div>
            </form>
          </CardContent>
        </Card>
      )}

      {/* Skills List */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {filteredSkills.map(skill => (
          <Card key={skill._id}>
            <CardContent>
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="font-medium text-gray-900">{skill.name}</h3>
                  <p className="text-sm text-gray-500">{skill.category}</p>
                  {skill.isCommon && (
                    <span className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-blue-100 text-blue-800">
                      Common
                    </span>
                  )}
                </div>
                <div className="flex gap-2">
                  <SecondaryButton onClick={() => handleEdit(skill)}>
                    <Edit size={16} />
                  </SecondaryButton>
                  <DangerButton onClick={() => handleDelete(skill._id)}>
                    <Trash2 size={16} />
                  </DangerButton>
                </div>
              </div>
              {skill.description && (
                <p className="mt-2 text-sm text-gray-600">{skill.description}</p>
              )}
              {skill.aliases?.length > 0 && (
                <div className="mt-2 flex flex-wrap gap-1">
                  {skill.aliases.map((alias, index) => (
                    <span 
                      key={index}
                      className="inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-gray-100 text-gray-800"
                    >
                      {alias}
                    </span>
                  ))}
                </div>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default SkillsSection;
