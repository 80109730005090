import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import './index.css'
import App from './App.jsx'
import config from './config'
import monitoringService from './services/monitoring'

// Initialize Application Insights if configured
if (config.monitoring.applicationInsights.enabled) {
  monitoringService.init(config.monitoring.applicationInsights.connectionString);
  console.log('Application Insights monitoring initialized');
}

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </StrictMode>,
)
