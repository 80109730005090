import { useState, useEffect } from 'react';
import { useNavigate, Outlet, useLocation } from 'react-router-dom';
import JobApplicationList from './JobApplicationList';
import { getJobApplications, updateJobApplication, getJobApplicationStats } from '../utils/api';
import { PlusCircle } from 'lucide-react';

const DashboardView = () => {
  const [applications, setApplications] = useState([]);
  const [stats, setStats] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();

  const showNewApplicationForm = location.pathname.includes('/applications/new');

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const [appsData, statsData] = await Promise.all([
          getJobApplications(),
          getJobApplicationStats()
        ]);
        setApplications(appsData);
        setStats(statsData);
        setLoading(false);
      } catch (error) {
        console.error('Failed to fetch dashboard data:', error);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };

    fetchData();

    // Refresh data when returning from form
    if (location.state?.refresh) {
      fetchData();
      // Clear the state to prevent unnecessary refreshes
      navigate(location.pathname, { replace: true, state: {} });
    }
  }, [location.state?.refresh, navigate, location.pathname]);

  const handleStatusChange = async (applicationId, newStatus) => {
    try {
      await updateJobApplication(applicationId, {
        status: newStatus
      });
      // Refresh the applications list
      const [appsData, statsData] = await Promise.all([
        getJobApplications(),
        getJobApplicationStats()
      ]);
      setApplications(appsData);
      setStats(statsData);
    } catch (error) {
      console.error('Failed to update application:', error);
      setError('Failed to update application status');
    }
  };

  const handleCreateNew = () => {
    navigate('applications/new');
  };

  const handleCloseForm = () => {
    navigate('/');
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-huntd-info"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-huntd-error/10 border border-huntd-error/20 text-huntd-error px-4 py-3 rounded relative" role="alert">
        <strong className="font-bold">Error! </strong>
        <span className="block sm:inline">{error}</span>
      </div>
    );
  }

  return (
    <div className="container mx-auto px-4 py-8 relative">
      {/* Modal for New Application Form */}
      {showNewApplicationForm && (
        <div className="fixed inset-0 bg-huntd-dark bg-opacity-50 z-40 flex items-center justify-center">
          <div className="bg-white rounded-lg shadow-xl w-full max-w-4xl max-h-[90vh] overflow-y-auto">
            <div className="p-6">
              <button
                onClick={handleCloseForm}
                className="absolute top-4 right-4 text-huntd-secondary hover:text-huntd-primary"
              >
                ×
              </button>
              <Outlet />
            </div>
          </div>
        </div>
      )}
      <div className="flex justify-between items-center mb-8">
        <h1 className="text-2xl font-bold text-huntd-primary">Job Application Dashboard</h1>
        <button
          onClick={handleCreateNew}
          className="inline-flex items-center px-4 py-2 bg-huntd-info text-white rounded-md hover:bg-huntd-info/80 focus:outline-none focus:ring-2 focus:ring-huntd-info focus:ring-offset-2"
        >
          <PlusCircle className="w-5 h-5 mr-2" />
          Create New Application
        </button>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-huntd-primary mb-2">Active Applications</h3>
          <p className="text-3xl font-bold text-huntd-info">
            {stats?.statusBreakdown.find(s => !['Rejected', 'Offer'].includes(s.status))?.count || 0}
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-huntd-primary mb-2">Interviews Scheduled</h3>
          <p className="text-3xl font-bold text-huntd-warning">
            {stats?.statusBreakdown.find(s => s.status === 'Interviewing')?.count || 0}
          </p>
        </div>
        <div className="bg-white rounded-lg shadow p-6">
          <h3 className="text-lg font-medium text-huntd-primary mb-2">Offers Received</h3>
          <p className="text-3xl font-bold text-huntd-success">
            {stats?.statusBreakdown.find(s => s.status === 'Offer')?.count || 0}
          </p>
        </div>
      </div>

      <JobApplicationList
        applications={applications}
        onStatusChange={handleStatusChange}
      />
    </div>
  );
};

export default DashboardView;
