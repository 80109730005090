import { useState, useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import ChatInterface from './components/ChatInterface';
import AdminPanel from './components/AdminPanel';
import ProfileManager from './components/ProfileManager';
import fetchWithAuth from './utils/fetch-with-auth';
import { API_BASE_URL } from './config';
import NavMenu from './components/NavMenu';
import DashboardView from './components/DashboardView';
import JobApplicationForm from './components/JobApplicationForm';
import LandingPage from './components/LandingPage';
import CVUpload from './components/CVUpload';
import { getRoles, getProjects, getSkills } from './utils/api';
import { AuthProvider, useAuth } from './contexts/AuthContext';
import monitoringService from './services/monitoring';

// Layout wrapper for authenticated pages
const AuthenticatedLayout = ({ children }) => {
  const [isMenuVisible, setIsMenuVisible] = useState(true);
  
  // Function to handle menu visibility changes
  const handleMenuVisibilityChange = (isVisible) => {
    setIsMenuVisible(isVisible);
  };
  
  return (
    <div className="min-h-screen bg-huntd-light">
      <NavMenu 
        onMenuVisibilityChange={handleMenuVisibilityChange}
      />
      <div className={`p-6 pt-16 transition-all duration-300 ${isMenuVisible ? 'md:ml-52' : ''}`}>
        {children}
      </div>
    </div>
  );
};

AuthenticatedLayout.propTypes = {
  children: PropTypes.node.isRequired
};

const App = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [data, setData] = useState({
    roles: [],
    projects: [],
    skills: []
  });
  const [chatHistory, setChatHistory] = useState([
    {
      role: 'assistant',
      content: "Hi! I'm an AI assistant that can tell you about my professional background. Feel free to ask about my experience, skills, or specific roles!"
    }
  ]);

  // Function to update chat history that we'll pass to ChatInterface
  const updateChatHistory = (newMessages) => {
    setChatHistory(newMessages);
  };

  // Function to fetch CV data
  const fetchData = async () => {
    try {
      setLoading(true);
      setError(null);
      
      const [roles, projects, skills] = await Promise.all([
        getRoles().catch(() => []),
        getProjects().catch(() => []),
        getSkills().catch(() => [])
      ]);

      setData({
        roles: (roles || []).map(role => ({
          ...role,
          startDate: role.startDate?.substring(0, 7), // Format as YYYY-MM
          endDate: role.endDate?.substring(0, 7) // Format as YYYY-MM if exists
        })),
        projects: projects || [],
        skills: (skills || []).map(s => s.name)
      });
    } catch (err) {
      console.error('Error fetching CV data:', err);
      // Don't show error for empty data
      if (err.message.includes('Bad Request')) {
        setData({
          roles: [],
          projects: [],
          skills: []
        });
      } else {
        setError('Error loading data. Please try again later.');
      }
    } finally {
      setLoading(false);
    }
  };

  // Initial data fetch
  useEffect(() => {
    fetchData();
  }, []);

  const AppContent = () => {
    const { user, loading: authLoading } = useAuth();
    const location = useLocation();
    const [profileComplete, setProfileComplete] = useState(null);
    
    // Track page views when location changes
    useEffect(() => {
      if (!authLoading) {
        const pageName = location.pathname === '/' ? 'Home' : 
                        location.pathname.charAt(1).toUpperCase() + 
                        location.pathname.slice(2).replace(/\//g, '-');
        
        monitoringService.trackPageView(pageName, {
          path: location.pathname,
          authenticated: !!user
        });
      }
    }, [location, authLoading, user]);
    
    // Set authenticated user context when user changes
    useEffect(() => {
      if (user) {
        monitoringService.setAuthenticatedUserContext(user);
      } else if (!authLoading) {
        monitoringService.clearAuthenticatedUserContext();
      }
    }, [user, authLoading]);

    // Check profile completion status
    useEffect(() => {
      const checkProfile = async () => {
        try {
          const data = await fetchWithAuth(`${API_BASE_URL}/api/profile/status`);
          setProfileComplete(data.complete);
        } catch (error) {
          console.error('Failed to check profile status:', error);
          setProfileComplete(false); // Default to incomplete on error
        }
      };

      if (user) {
        checkProfile();
      }
    }, [user]);

    if (authLoading || (user && profileComplete === null)) {
      return (
        <div className="flex items-center justify-center h-screen">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-huntd-info"></div>
        </div>
      );
    }

    return (
      <Routes>
        <Route path="/" element={<LandingPage />} />
        {!user ? (
          <Route path="*" element={<Navigate to="/" replace />} />
        ) : (
          <Route element={<AuthenticatedLayout>
            <Routes>
              {!profileComplete ? (
                <>
                  <Route path="/profile" element={<ProfileManager />} />
                  <Route path="*" element={<Navigate to="/profile" replace />} />
                </>
              ) : (
                <>
                  <Route path="/dashboard" element={<DashboardView />}>
                    <Route path="applications/new" element={<JobApplicationForm />} />
                  </Route>
                  <Route path="/chat" element={
                    <ChatInterface
                      messages={chatHistory}
                      onUpdateMessages={updateChatHistory}
                    />
                  } />
                  <Route path="/cv-upload" element={
                    <CVUpload 
                      onUploadComplete={(result) => {
                        if (result.success) {
                          // Refresh data after successful upload
                          fetchData();
                        }
                      }}
                    />
                  } />
                  <Route path="/admin" element={
                    <AdminPanel 
                      data={data} 
                      setData={setData} 
                      loading={loading} 
                      error={error} 
                    />
                  } />
                  <Route path="/profile" element={<ProfileManager />} />
                  <Route path="/" element={<Navigate to="/dashboard" replace />} />
                  <Route path="*" element={<Navigate to="/dashboard" replace />} />
                </>
              )}
            </Routes>
          </AuthenticatedLayout>} />
        )}
      </Routes>
    );
  };

  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
